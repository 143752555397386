import EntityType from "../models/EntityType"
import CounselorEntity from "./CounselorEntity"

export default class CounselingEntity implements EntityType {
  readonly id: string
  readonly status: string
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string | null
  readonly canceledAt: string | null
  readonly doneAt: string | null
  readonly counselor: CounselorEntity | null

  constructor(json: any) {
    this.id = json["id"]
    this.status = json["status"]
    this.videoCallURL = json["video_call_url"]
    this.createdAt = json["created_at"]
    this.reservationAt = json["reservation_at"]
    this.canceledAt = json["canceled_at"]
    this.doneAt = json["done_at"]
    this.counselor = new CounselorEntity(json["counselor"])
  }
}
