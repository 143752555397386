import MeEntity from "../entities/MeEntity"
import AddressModel from "./AddressModel"
import ModelType from "./ModelType"

export default class MeModel implements ModelType {
  readonly fullName: string
  readonly email: string
  readonly phoneNumber: string
  readonly address: AddressModel | null
  readonly paymentMethod: "credit_card" | "bank_transfer" | null
  readonly useAutoPayment: boolean
  readonly autoPaymentTimes: string | null

  constructor(entity: MeEntity) {
    this.fullName = entity.name || ""
    this.email = entity.email || ""
    this.phoneNumber = entity.phone || ""
    this.address = entity.address ? new AddressModel(entity.address) : null
    this.paymentMethod = entity.paymentMethod as any
    this.useAutoPayment = entity.useAutoPayment
    this.autoPaymentTimes = entity.autoPaymentTimes
  }

  get paymentMethodValue() {
    switch (this.paymentMethod) {
      case "credit_card":
        return "クレジットカード"
      case "bank_transfer":
        return "銀行振り込み"
      case null:
        return null
      default:
        return this.paymentMethod
    }
  }
}
