import React from "react"
import { Button, Image, Spacer, Text } from "@chakra-ui/react"
import images from "../ui-helpers/ImageSources"

type IconType =
  | "customer"
  | "backHomeArrow"
  | "clipboardOrange"
  | "clipboardGray"
  | "calendar"
  | "stethoscopeOrange"
  | "stethoscopeGray"

type Props = {
  isPressed: boolean
  width: string
  height: string
  icon: IconType
  title: string
  fontSize: string
  iconHeight: string
  onClick: () => void
}

const IconButton: React.FC<Props> = (props: Props) => {
  const { isPressed, width, height, icon, title, fontSize, iconHeight } = props

  const background = isPressed
    ? "linear-gradient(133.55deg, #EEEEEE 0%, #E3E3E3 100%)"
    : "linear-gradient(133.55deg, #F8F8F8 0%, #E8E8E8 100%)"

  const boxShadow = isPressed
    ? "2px 2px 4px #FAFAFA, inset 4px 4px 8px 3px rgba(140, 140, 140, 0.35)"
    : "3px 4px 3px #CBCBCB, inset 2px 2px 2px #FFFFFF, inset -1px -1px 4px #B9B9B9, inset -1px -1px 0px 2px #FAFAFA, inset 1px 1px 8px 3px #E7E7E7"

  return (
    <Button
      display="flex"
      flexDirection="column"
      background={background}
      boxShadow={boxShadow}
      height={height}
      width={width}
      onClick={props.onClick}
    >
      <Image src={images[icon]} height={iconHeight} alt={icon} />
      <Spacer flex="0.2" />
      <Text fontSize={props.fontSize}>{title}</Text>
    </Button>
  )
}

export default IconButton
