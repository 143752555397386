declare global {
  interface Window {
    Multipayment: any
  }
}

class multipayment {
  static info: any

  static init(shopId) {
    multipayment.info = window.Multipayment
    multipayment.info.init(shopId)
  }

  static getToken(params) {
    return new Promise<string>((resolve, reject) => {
      const callbackFunctionName = `__gmoPgGetTokenCallback${Math.random()
        .toString(8)
        .substring(2)}`
      window[callbackFunctionName] = this.gmoPgGetTokenCallback((token) => {
        delete window[callbackFunctionName]
        if (token) {
          resolve(token)
        } else {
          reject()
        }
      })
      multipayment.info.getToken(params, callbackFunctionName)
    })
  }

  static gmoPgGetTokenCallback(callback: (token: string | null) => void) {
    return (response) => {
      if (response.resultCode === "000") {
        const token = response.tokenObject.token
        callback(token)
      } else {
        callback(null)
      }
    }
  }
}

export default multipayment
