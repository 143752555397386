import AddressEntity from "../entities/AddressEntity"
import ModelType from "./ModelType"

export default class AddressModel implements ModelType {
  readonly id: number
  readonly zip: string | null // 郵便番号
  readonly state: string | null // 都道府県
  readonly city: string | null // 市区町村
  readonly line1: string | null // 町名番地
  readonly line2: string | null // 建物・部屋番号・その他
  readonly shipFrom: string | null
  readonly receivingWay: string | null
  readonly default: boolean | null
  readonly updatedAt: string

  constructor(entity: AddressEntity) {
    this.id = entity.id
    this.zip = entity.addressZip
    this.state = entity.addressState
    this.city = entity.addressCity
    this.line1 = entity.addressLine1
    this.line2 = entity.addressLine2
    this.shipFrom = entity.addressShipFrom
    this.receivingWay = entity.addressReceivingWay
    this.default = entity.default
    this.updatedAt = entity.updatedAt
  }

  oneLine(): string | null {
    const { state, city, line1, line2 } = this
    return `${state || ""}${city || ""}${line1 || ""}${line2 || ""}`
  }
}
