import { Box, CSSReset, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import PageTitleSection from "../../ui-components/PageTitleSection"
import PrescriptionModel from "../../models/PrescriptionModel"
import { requestAllPrescriptions } from "./PrescriptionsPageController"
import PrescriptionsListSection from "../../ui-components/PrescriptionsListSection"

type Props = {
  history: H.History
}

const PrescriptionsPage: React.FC<Props> = (props: Props) => {
  const [prescriptions, setPrescriptions] = useState<PrescriptionModel[]>()
  const [requestError, setRequestError] = useState<string | null>()

  useEffect(() => {
    preparePrescriptions()
    window.scrollTo(0, 0)
  }, [])

  async function preparePrescriptions() {
    try {
      setPrescriptions(await requestAllPrescriptions())
      setRequestError(null)
    } catch (e) {
      console.error(e)
      const message = `エラーが発生しました\n${e.name || ""} ${e.message}`
      setRequestError(message)
    }
  }

  return requestError ? (
    <Text height="100vh">{requestError}</Text>
  ) : (
    <>
      <CSSReset />
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title="処方箋履歴" />
      <PrescriptionsListSection
        prescriptions={prescriptions || []}
        shouldShowMoreButton={false}
        history={props.history}
      />
      <Box height="80px" />
    </>
  )
}

export default PrescriptionsPage
