import EntityType from "../models/EntityType"
import DoctorEntity from "./DoctorEntity"

export default class UnableToPrescribeMedicalExaminationEntity
  implements EntityType {
  readonly id: string
  readonly status: string
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string
  readonly canceledAt: string
  readonly doneAt: string | null
  readonly doctor: DoctorEntity

  constructor(json: any) {
    this.id = json["id"]
    this.status = json["status"]
    this.videoCallURL = json["video_call_url"]
    this.createdAt = json["created_at"]
    this.reservationAt = json["reservation_at"]
    this.canceledAt = json["canceled_at"]
    this.doneAt = json["done_at"]
    this.doctor = new DoctorEntity(json["doctor"])
  }
}
