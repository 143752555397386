import React from "react"
import {
  Button,
  Image,
  Spacer,
  Text,
  Box,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import images from "../ui-helpers/ImageSources"

type IconColorType = "orange" | "gray"
type IconType =
  | "magnifier"
  | "calendar"
  | "userCircle"
  | "cart"
  | "door"
  | "caution"

type Props = {
  width: string
  height: string
  text: string
  textBelow?: string
  textAlign?: "center" | "left"
  iconColor: IconColorType
  icon?: IconType | null
  iconHeight?: string | null
  onClick?: () => void
  link?: string | null
  linkTarget?: boolean | null
  disabled?: boolean
}

const ArrowButton: React.FC<Props> = (props: Props) => {
  const { width, height, icon, iconColor, text, disabled } = props

  const arrowIcon =
    iconColor === "orange" ? images.orangeArrow : images.nextGrayArrow

  const background = "linear-gradient(133.55deg, #F8F8F8 0%, #E8E8E8 100%)"
  const boxShadow =
    "3px 4px 3px #CBCBCB, inset 2px 2px 2px #FFFFFF, inset -1px -1px 4px #B9B9B9, inset -1px -1px 0px 2px #FAFAFA, inset 1px 1px 8px 3px #E7E7E7 !important"

  return (
    <LinkBox flex="1">
      <Button
        display="flex"
        pl="24px"
        pr="24px"
        fontSize="18px"
        background={background}
        boxShadow={boxShadow}
        height={height}
        width={width}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {icon ? (
          <Image src={images[icon]} height={props.iconHeight!} alt={icon} />
        ) : null}
        <Box flex="0.18" />
        <Box
          display="flex"
          flexDirection="column"
          width={props.textAlign === "center" ? "100%" : undefined}
        >
          <Text
            whiteSpace="pre-wrap"
            textAlign={props.textAlign ? props.textAlign : "left"}
            lineHeight="27px"
            fontSize="18px"
          >
            {text}
          </Text>
          {props.textBelow && (
            <Text
              whiteSpace="pre-wrap"
              textAlign="center"
              lineHeight="20px"
              fontSize="12px"
            >
              {props.textBelow}
            </Text>
          )}
        </Box>
        <Spacer flex="1" />
        <Image src={arrowIcon} height="24px" alt="次へ矢印" />
      </Button>
      {props.link ? (
        <LinkOverlay
          href={props.link}
          target={props.linkTarget ? "_blank" : ""}
        />
      ) : null}
    </LinkBox>
  )
}

export default ArrowButton
