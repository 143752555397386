import MedicalExaminationsListEntity from "../entities/MedicalExaminationsListEntity"
import CounselingModel from "./CounselingModel"
import MedicalExaminationModel from "./MedicalExaminationModel"
import ModelType from "./ModelType"

export default class MedicalExaminationsListModel implements ModelType {
  readonly medicalExaminations: MedicalExaminationModel[]
  readonly counseling: CounselingModel | null

  constructor(entity: MedicalExaminationsListEntity) {
    this.medicalExaminations = entity.medicalExaminations
      ? entity.medicalExaminations?.map((m) => new MedicalExaminationModel(m))
      : []
    this.counseling = entity.counseling
      ? new CounselingModel(entity.counseling)
      : null
  }
}
