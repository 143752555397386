import EntityType from "../models/EntityType"

export default class CounselorEntity implements EntityType {
  readonly name: string
  readonly email: string
  readonly meetIn: string | null
  readonly phone: string | null

  constructor(json: any) {
    this.name = json["name"]
    this.email = json["email"]
    this.meetIn = json["meet_in"]
    this.phone = json["phone"]
  }
}
