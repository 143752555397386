import EntityType from "../models/EntityType"
import AddressEntity from "./AddressEntity"
import PrescriptionDetailEntity from "./PrescriptionDetailEntity"
import PrescriptionShippingEntity from "./PrescriptionShippingEntity"

export default class PrescriptionEntity implements EntityType {
  readonly id: string
  readonly status: string
  readonly amount: string | null
  readonly createdAt: string
  readonly canceledAt: string | null
  readonly deliveredAt: string | null
  readonly purchasedAt: string | null
  readonly trackingNumber: string | null
  readonly receivingWay: string | null
  readonly shipFrom: string | null
  readonly paymentMethod: string | null
  readonly key: string | null
  readonly code: string | null
  readonly prescriptionURL: string | null
  readonly kuronekoyamatoTrackingURL: string | null
  readonly isFirstMonthFree: boolean
  readonly isSplitDispensing: boolean
  readonly splitDispensingTimes: number | null
  readonly totalSplitDispensingTimes: number | null
  readonly isLastSplitDispensing: boolean
  readonly runOutDate: string | null
  readonly address: AddressEntity
  readonly mainPrescriptionMonth: number
  readonly prescriptionDetails: PrescriptionDetailEntity[]
  readonly priscriptionShippings: PrescriptionShippingEntity[]

  constructor(json: any) {
    this.id = json["id"]
    this.status = json["status"]
    this.amount = json["amount"]
    this.createdAt = json["created_at"]
    this.canceledAt = json["canceled_at"]
    this.deliveredAt = json["delivered_at"]
    this.purchasedAt = json["purchased_at"]
    this.trackingNumber = json["tracking_number"]
    this.receivingWay = json["receiving_way"]
    this.shipFrom = json["ship_from"]
    this.paymentMethod = json["payment_method"]
    this.key = json["key"]
    this.code = json["code"]
    this.prescriptionURL = json["prescription_url"]
    this.kuronekoyamatoTrackingURL = json["kuronekoyamato_tracking_url"]
    this.isFirstMonthFree = json["is_first_month_free"]
    this.isSplitDispensing = json["is_split_dispensing"]
    this.splitDispensingTimes = json["split_dispensing_times"]
    this.totalSplitDispensingTimes = json["total_split_dispensing_times"]
    this.isLastSplitDispensing = json["last_split_dispensing"]
    this.runOutDate = json["run_out_date"]
    this.address = new AddressEntity(json["address"])
    this.mainPrescriptionMonth = json["main_prescription_month"]
    this.prescriptionDetails = json["prescription_details"]
      ? json["prescription_details"].map(
          (s: any) => new PrescriptionDetailEntity(s)
        )
      : []
    this.priscriptionShippings = json["prescription_shippings"]
      ? json["prescription_shippings"].map(
          (s: any) => new PrescriptionShippingEntity(s)
        )
      : []
  }
}
