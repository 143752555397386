import EntityType from "../models/EntityType"
import PrescriptionEntity from "./PrescriptionEntity"
import PerscriptionEntity from "./PrescriptionEntity"

export default class PrescriptionsListEntity implements EntityType {
  readonly prescriptions: Array<PerscriptionEntity> | null

  constructor(json: any) {
    this.prescriptions = json["prescriptions"].map(
      (p) => new PrescriptionEntity(p)
    )
  }
}
