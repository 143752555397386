import React, { useCallback, useEffect, useState } from "react"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import * as H from "history"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import { Box, Text } from "@chakra-ui/react"
import PageTitleSection from "../../ui-components/PageTitleSection"
import formatExpire from "../../../utils/formatCardExpire"
import ArrowButton from "../../ui-components/ArrowButton"
import paths from "../../helpers/ROUTES_PATH"
import { useLocation } from "react-router-dom"

type Props = {
  history: H.History
}

type Card = {
  card_number: string
  expire: string
  holder_name: string
  sequence: string
  is_default: boolean
}

type LocationState = {
  message: string
}

const CustomerCardEditPage: React.FC<Props> = (props: Props) => {
  const [cards, setCards] = useState<Card[]>([])
  const [submitting, setSubmitting] = useState(false)

  const createHeaders = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    return headers
  }

  const fetchCards = useCallback(async () => {
    const headers = createHeaders()
    const res = await fetch("/api/cards", { headers })
    const cardResponse = (await res.json()).cards
    setCards(cardResponse)
  }, [])

  const updateDefaultSequence = async (sequence: string) => {
    setSubmitting(true)
    const headers = createHeaders()
    await fetch(`/api/cards/${sequence}/update_default_card_sequence`, {
      method: "POST",
      headers,
    })
    setSubmitting(false)
    window.location.reload()
  }

  const handleOnClickNewCard = useCallback(() => {
    props.history.push({
      pathname: paths.creditCardCreate,
    })
  }, [props.history])

  const handleOnClickEditCard = useCallback(
    (sequence: string) => {
      props.history.push({
        pathname: paths.creditCardCreate,
        state: { sequence: sequence },
      })
    },
    [props.history]
  )

  const location = useLocation<LocationState>()

  useEffect(() => {
    fetchCards()
  }, [fetchCards])

  return (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title={"クレジットカードの変更"} />
      {location.state?.message && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="mx-2"
          mb="34px"
          border="1px"
          borderColor="#70CD14"
          bgColor="rgba(112, 205, 20, 0.08)"
          borderRadius="8px"
          height="45px"
        >
          <Text className="text-success">{location.state?.message}</Text>
        </Box>
      )}
      {
        <div className="d-flex flex-column px-2">
          {cards.map((card) => {
            return (
              <Box
                key={card.sequence}
                className="p-3 mb-3 d-flex justify-content-between align-items-center"
                style={
                  card.is_default
                    ? { border: `2px solid ${TenetColors.orange._200}` }
                    : {}
                }
                bg={TenetColors.white._100}
              >
                <div>
                  <div className="card-number d-flex">
                    <span>カード番号: </span>
                    {card.card_number}
                  </div>
                  <div className="card-holder-name d-flex">
                    <span>カード氏名: </span>
                    {card.holder_name}
                  </div>
                  <div className="card-expire d-flex">
                    <span>有効期限: </span>
                    {formatExpire(card.expire)}
                  </div>
                </div>

                <div className="d-flex justify-content-end flex-column align-items-end">
                  <div className="mb-2">
                    <ArrowButton
                      width="auto"
                      height="45px"
                      text="編集"
                      iconColor="orange"
                      icon={null}
                      iconHeight={null}
                      onClick={() => handleOnClickEditCard(card.sequence)}
                      linkTarget={false}
                    />
                  </div>
                  {!card.is_default && (
                    <ArrowButton
                      width="auto"
                      height="45px"
                      text="決済に利用する"
                      iconColor="orange"
                      icon={null}
                      iconHeight={null}
                      onClick={() => updateDefaultSequence(card.sequence)}
                      linkTarget={false}
                      disabled={submitting}
                    />
                  )}
                </div>
              </Box>
            )
          })}
          {cards.length < 5 && (
            <ArrowButton
              width="100%"
              height="60px"
              text="新規カードの追加"
              iconColor="orange"
              icon={null}
              iconHeight={null}
              onClick={handleOnClickNewCard}
              linkTarget={false}
            />
          )}
        </div>
      }
    </Box>
  )
}

export default CustomerCardEditPage
