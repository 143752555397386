import { Avatar, Box, CSSReset, Text, Image } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import DoctorModel from "../../models/DoctorModel"
import { useLocation } from "react-router"

type Props = {
  history: H.History
}

const DoctorDetailPage: React.FC<Props> = (props: Props) => {
  const [doctor, setDoctor] = useState<DoctorModel>()
  const location = useLocation()

  useEffect(() => {
    const state = location.state as Object
    const d = state["doctor"] as DoctorModel
    setDoctor(d)
    window.scrollTo(0, 0)
  }, [location.state])

  function topComponent() {
    return (
      <>
        <Avatar
          m="0 auto"
          src={doctor?.profileImageURL || undefined}
          width="157px"
          height="157px"
        />
        <Box height="16px" />
        <Text>{doctor?.name || ""}</Text>
        <Box height="8px" />
        <Text fontWeight="500">{doctor?.career || ""}</Text>
        <Box height="40px" />
      </>
    )
  }

  function careerComponent() {
    return (
      <>
        <Text>医籍登録年</Text>
        <Box height="8px" />
        <Text fontWeight="500">{doctor?.registrationDate || ""}</Text>

        <Box height="40px" />
      </>
    )
  }

  function commentComponent() {
    return (
      <>
        <Text>医師からのコメント</Text>
        <Box height="8px" />
        <Text lineHeight="34px" fontWeight="500" textAlign="left">
          {doctor?.commentFromDoctor}
        </Text>
        <Box height="40px" />
      </>
    )
  }

  function certificateImageComponent(url: string) {
    return (
      <>
        <Text>オンライン研修修了証</Text>
        <Box height="8px" />
        <Image
          src={url}
          width="240px"
          m="auto"
          alt="厚生労働省が発行するオンライン診療研修の修了証"
        />
      </>
    )
  }

  return (
    <>
      <CSSReset />
      <BackButtonTopBar history={props.history} />
      <Box height="52px" />
      <Box textAlign="center">
        {topComponent()}
        {doctor?.career ? careerComponent() : null}
        {doctor?.commentFromDoctor ? commentComponent() : null}
        {doctor?.certificateImageURL
          ? certificateImageComponent(doctor.certificateImageURL)
          : null}
        <Box height="80px" />
      </Box>
    </>
  )
}

export default DoctorDetailPage
