const ROUTES_PATH = {
  customerHome: "/customer_app",
  customerDetail: "/customer_app/customer_detail",
  customerDetailChange: "/customer_app/customer_detail/change",
  doctorDetail: "/customer_app/doctor/",
  prescriptions: "/customer_app/prescriptions",
  medicalExaminations: "/customer_app/medical_examinations",
  customersEdit: "/customers/edit",
  creditCardEdit: "/customer_app/credit_card/edit",
  creditCardCreate: "/customer_app/credit_card/create",
  customersSignOut: "/customers/sign_out",
  addressesEdit: "/customer_app/addresses/edit",
  PrescriptionShippingDetail: "/customer_app/prescription_shipping_detail/",
  PrescriptionShippingChange: "/customer_app/prescription_shipping/edit/",
  trade: "/trade",
  terms: "/terms",
  privacy: "/privacy",
  medicalPlan: "/medical_plan",
  customerScheduleCounselings: "/customer_schedule_counselings",
  customerScheduleCounselingsOverwrite:
    "/customer_schedule_counselings/overwrite",
  customerScheduleMedicalExamination: "/customer_schedule_medical_examinations",
  customerScheduleMedicalExaminationOverwrite:
    "/customer_schedule_medical_examinations/overwrite",
  customerScheduleUnableToPrescribeMedicalExamination:
    "/customer_schedule_un_medical_examinations",
  customerScheduleUnableToPrescribeMedicalExaminationOverwrite:
    "/customer_schedule_un_medical_examinations/overwrite",
  counselingSurvey: "/counseling_survey",
  medicalExamSurvey: "/medical_exam_survey",
  thanksSurvey: "/thanks_survey",
  customerScheduleSideEffectExamination:
    "/customer_schedule_side_effect_examinations",
  customerScheduleSideEffectExaminationOverwrite:
    "/customer_schedule_side_effect_examinations/overwrite",
  customerScheduleMedicalExamination5min:
    "/customer_schedule_medical_examinations_5min",
  customerScheduleMedicalExamination5minOverwrite:
    "/customer_schedule_medical_examinations_5min/overwrite",
  webInterview: "/web_interview",
  thanksWebInterview: "/thanks_web_interview",
  followup: "/followup",
  thanksFollowup: "/thanks_followup",
  medicalExamWebInterview: "/medical_examination_web_interview",
  customerScheduleImmediateExamination:
    "/customer_schedule_first_immediate_medical_examinations",
  customerScheduleImmediateExaminationOverwrite:
    "/customer_schedule_first_immediate_medical_examinations/overwrite",
} as const

export default ROUTES_PATH
