import PrescriptionDetailEntity from "../entities/PrescriptionDetailEntity"
import ModelType from "./ModelType"

export default class PrescriptionDetailModel implements ModelType {
  readonly medicineName: string
  readonly medicineNameForCustomer: string | null
  readonly spec: string
  readonly dosagePerDay: string
  readonly day: number | null
  readonly month: number | null

  constructor(entity: PrescriptionDetailEntity) {
    this.medicineName = entity.medicineName
    this.medicineNameForCustomer = entity.medicineNameForCustomer
    this.spec = entity.spec
    this.dosagePerDay = entity.dosagePerDay
    this.day = entity.day
    this.month = entity.month
  }

  displayMedicineAmount(): string {
    if (this.month) {
      return `${this.month}ヶ月`
    } else if (this.day) {
      return `${this.day}日`
    }
    return ""
  }

  displayText(): string {
    if (this.medicineNameForCustomer) {
      return `${this.medicineNameForCustomer}　${this.dosagePerDay}`
    }

    if (this.spec == "") {
      return `${this.medicineName}　${this.dosagePerDay}`
    } else {
      return `${this.medicineName}${this.spec}　${this.dosagePerDay}`
    }
  }
}
