import ModelType from "./ModelType"
import PrescriptionEntity from "../entities/PrescriptionEntity"
import AddressModel from "./AddressModel"
import PrescriptionDetailModel from "./PrescriptionDetailModel"
import MomentUtility from "../helpers/MomentUtility"
import PrescriptionShippingModel from "./PrescriptionShippingModel"
import moment from "moment"

const enum PrescriptionStatusType {
  beforePurchase = "before_purchase",
  waitingBankTransfer = "waiting_bank_transfer",
  purchased = "purchased",
  delivered = "delivered",
  canceled = "canceled",
  expired = "expired",
}

export default class PrescriptionModel implements ModelType {
  readonly id: string
  readonly status: PrescriptionStatusType
  readonly price: number | null
  readonly createdAt: string
  readonly canceledAt: string | null
  readonly deliveredAt: string | null
  readonly purchasedAt: string | null
  private readonly trackingNumber: string | null
  readonly shipFrom: string | null
  readonly paymentMethod: string | null
  readonly key: string | null
  readonly code: string | null
  readonly prescriptionURL: string | null
  private readonly trackingURL: string | null
  readonly addressCity: string | null
  readonly addressLine1: string | null
  readonly addressLine2: string | null
  readonly addressState: string | null
  readonly addressZip: string | null
  readonly receivingWay: string | null
  readonly address: AddressModel
  readonly mainPrescriptionMonth: number
  readonly prescriptionDetails: PrescriptionDetailModel[]
  readonly prescriptionShippings: PrescriptionShippingModel[] // 薬剤不足による分割配送への対応として配送情報が分割されているケースがある
  readonly isFirstMonthFree: boolean
  readonly isSplitDispensing: boolean
  readonly splitDispensingTimes: number | null
  readonly totalSplitDispensingTimes: number | null
  readonly isLastSplitDispensing: boolean
  readonly runOutDate: string | null

  constructor(entity: PrescriptionEntity) {
    this.id = entity.id
    this.status = entity.status as PrescriptionStatusType
    this.price = entity.amount ? parseInt(entity.amount) : null
    this.createdAt = entity.createdAt
    this.canceledAt = entity.canceledAt
    this.deliveredAt = entity.deliveredAt
    this.purchasedAt = entity.purchasedAt
    this.trackingNumber = entity.trackingNumber
    this.shipFrom = entity.shipFrom
    this.paymentMethod = entity.paymentMethod
    this.key = entity.key
    this.code = entity.code
    this.prescriptionURL = entity.prescriptionURL
    this.trackingURL = entity.kuronekoyamatoTrackingURL
    this.shipFrom = entity.shipFrom
    this.receivingWay = entity.receivingWay
    this.address = new AddressModel(entity.address)
    this.mainPrescriptionMonth = entity.mainPrescriptionMonth
    this.prescriptionDetails = entity.prescriptionDetails.map(
      (d) => new PrescriptionDetailModel(d)
    )
    this.prescriptionShippings = entity.priscriptionShippings.map(
      (s) => new PrescriptionShippingModel(s)
    )
    this.isFirstMonthFree = entity.isFirstMonthFree
    this.isSplitDispensing = entity.isSplitDispensing
    this.isLastSplitDispensing = entity.isLastSplitDispensing
    this.runOutDate = entity.runOutDate
    this.splitDispensingTimes = entity.splitDispensingTimes
    this.totalSplitDispensingTimes = entity.totalSplitDispensingTimes
  }

  deliveredDateJPN(): string {
    if (!this.deliveredAt) {
      return ""
    }
    const m = new MomentUtility(this.deliveredAt)
    return `${m.year()}年${m.month()}月${m.date()}日`
  }

  hasShippedAlready(): boolean {
    return (
      this.status === PrescriptionStatusType.delivered ||
      this.status === PrescriptionStatusType.canceled ||
      this.status === PrescriptionStatusType.expired
    )
  }

  displayPrescribedDate(): string {
    if (!this.createdAt) {
      return ""
    }
    const m = new MomentUtility(this.createdAt)
    return `${m.year()}年${m.month()}月${m.date()}日`
  }

  displayPurchasedDate(): string {
    if (!this.purchasedAt) {
      return ""
    }
    const m = new MomentUtility(this.purchasedAt)
    return `${m.year()}年${m.month()}月${m.date()}日`
  }

  displayShippingStatus(): string {
    if (this.status === PrescriptionStatusType.beforePurchase) {
      return "購入前"
    } else if (this.status === PrescriptionStatusType.canceled) {
      return "キャンセル"
    } else if (this.status === PrescriptionStatusType.delivered) {
      if (this.prescriptionShippings.length > 0) {
        const total = this.prescriptionShippings.length
        const delivered = this.prescriptionShippings.filter(
          (s) => s.status === "delivered"
        ).length
        return `${total}分の${delivered}発送済`
      } else {
        return "発送済"
      }
    } else if (this.status === PrescriptionStatusType.expired) {
      return "有効期限切れ"
    } else if (this.status === PrescriptionStatusType.purchased) {
      return "支払い済"
    } else if (this.status === PrescriptionStatusType.waitingBankTransfer) {
      return "振込待ち"
    } else {
      return ""
    }
  }

  latestTrackingNumber(): string | null {
    if (this.prescriptionShippings.length === 0 && this.trackingNumber) {
      return this.trackingNumber
    } else {
      // 分割配送の場合はprescriptionShippingsからtrackingNumberを取得
      const latest = this.latestDelivered()
      return latest ? latest.trackingNumber : null
    }
  }

  kuronekoTrackigURL(): string | null {
    if (this.prescriptionShippings.length === 0 && this.trackingNumber) {
      return this.trackingURL
    } else {
      // 分割配送の場合はprescriptionShippingsからURLを生成
      const latest = this.latestDelivered()
      return latest
        ? `https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${latest?.trackingNumber}`
        : null
    }
  }

  // 分割配送の場合で、一番最近配送されたもの
  private latestDelivered(): PrescriptionShippingModel | null {
    const delivered = this.prescriptionShippings
      .filter((s) => {
        return s.status === "delivered" && s.trackingNumber
      })
      .sort((a, b) => moment(a.deliveredAt).diff(b.deliveredAt))
    return delivered.length === 0 ? null : delivered[delivered.length - 1]
  }
}
