import CustomerHomeInfo from "./CustomerHomeInfo"
import {
  getMe,
  getTopInfo,
  getMedicalExaminations,
  getPrescriptions,
} from "../../api-helpers/APICalls"

export async function requestCustomerHomeInfo(): Promise<CustomerHomeInfo> {
  try {
    const requests: Promise<any>[] = [
      getMe(),
      getTopInfo(),
      getMedicalExaminations(3),
      getPrescriptions(3),
    ]
    const [
      meModel,
      topInfoModel,
      medicalExaminationsListModel,
      prescriptionsListModel,
    ] = await Promise.all(requests) // 並列にリクエストを行い、全て揃うまで待つ

    return new CustomerHomeInfo({
      me: meModel,
      topInfo: topInfoModel,
      medicalExaminations: medicalExaminationsListModel,
      prescriptions: prescriptionsListModel,
    })
  } catch (e) {
    throw e
  }
}
