import UnableToPrescribeMedicalExaminationEntity from "../entities/UnableToPrescribeMedicalExaminationEntity"
import DoctorModel from "./DoctorModel"
import ModelType from "./ModelType"
import MomentUtility from "../helpers/MomentUtility"

const enum UnableToPrescribeMedicalExaminationStatusType {
  reserved = "reserved",
  draft = "draft",
  done = "done",
  canceled = "canceled",
}

export default class UnableToPrescribeMedicalExaminationModel
  implements ModelType {
  readonly id: string
  readonly status: UnableToPrescribeMedicalExaminationStatusType
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string
  readonly canceledAt: string | null
  readonly doneAt: string | null
  readonly doctor: DoctorModel | null

  constructor(entity: UnableToPrescribeMedicalExaminationEntity) {
    this.id = entity.id
    this.status = entity.status as UnableToPrescribeMedicalExaminationStatusType
    this.videoCallURL = entity.videoCallURL
    this.createdAt = entity.createdAt
    this.reservationAt = entity.reservationAt
    this.canceledAt = entity.canceledAt
    this.doneAt = entity.doneAt
    this.doctor = entity.doctor ? new DoctorModel(entity.doctor) : null
  }

  reservationTimeWithoutYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }

  reservationTimeWithYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.year()}年${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }

  isStatusActive(): boolean {
    return (
      this.status === UnableToPrescribeMedicalExaminationStatusType.reserved
    )
  }
}
