import React from "react"
import ArrowButton from "../../../ui-components/ArrowButton"

type IconType = "magnifier" | "calendar" | "cart"

type Props = {
  icon: string
  url?: string
  text: string
  onClick?: () => void
}

const CustomerHomeActionButtonSection: React.FC<Props> = (props: Props) => {
  return (
    <>
      <ArrowButton
        iconColor="orange"
        width="100%"
        height="72px"
        iconHeight="24px"
        icon={props.icon as IconType}
        text={props.text}
        link={props.url}
        linkTarget={true}
        onClick={props.onClick}
      />
    </>
  )
}

export default CustomerHomeActionButtonSection
