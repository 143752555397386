import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import {
  Text,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  Button,
  Checkbox,
  ChakraProvider,
  Flex,
  HStack,
  Divider,
  CheckboxGroup,
  extendTheme,
} from "@chakra-ui/react"
import ArrowButton from "../../ui-components/ArrowButton"
import PrescriptionShippingModel from "../../models/PrescriptionShippingModel"
import MeModel from "../../models/MeModel"
import paths from "./../../helpers/ROUTES_PATH"
import PageTitleSection from "../../ui-components/PageTitleSection"
import AddressModel from "../../models/AddressModel"
import {
  getAddresses,
  updateShipping,
  updateShippingAllAfter,
} from "../../api-helpers/APICalls"
import { getMe } from "../CustomerDetail/CustomerDetailPageController"
import { Japanese } from "../../../public/components/PrescriptionPage/types"
import PrescriptionModel from "../../models/PrescriptionModel"

type Props = {
  history: H.History
}

const PrescriptionShippingEditPage: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const [shipping, setShipping] = useState<PrescriptionShippingModel>()
  const [prescription, setPrescription] = useState<PrescriptionModel>()
  const [addresses, setAddresses] = useState<AddressModel[]>([])
  const [requestError, setRequestError] = useState<string | null>()
  const [submitError, setSubmitError] = useState<string | null>()
  const [isCompletedAlertOpen, setIsCompletedAlertOpen] = useState<boolean>(
    false
  )
  const [isUpdateAllAfter, setIsUpdateAllAfter] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [me, setMe] = useState<MeModel>()

  const handleDefaultSelect = useCallback(() => {
    const address = addresses.find((address) => {
      return (
        shipping?.addressState === address.state &&
        shipping?.addressCity === address.city &&
        shipping?.addressZip === address.zip &&
        shipping?.addressLine1 === address.line1 &&
        shipping?.addressLine2 === address.line2 &&
        shipping?.shipFrom === address.shipFrom &&
        shipping?.receivingWay === address.receivingWay
      )
    })

    if (address) setSelectedId(address.id)
  }, [
    addresses,
    shipping?.addressCity,
    shipping?.addressLine1,
    shipping?.addressLine2,
    shipping?.addressState,
    shipping?.addressZip,
    shipping?.receivingWay,
    shipping?.shipFrom,
  ])

  useEffect(() => {
    const state = location.state as Object
    fetchAddress()
    requestMe()
    setShipping(state["prescriptionShipping"])
    setPrescription(state["prescription"])
  }, [location.state])

  useEffect(() => {
    handleDefaultSelect()
  }, [addresses, shipping, handleDefaultSelect])

  const fetchAddress = async () => {
    const addressList = await getAddresses()
    setAddresses(addressList.addresses)
  }

  async function requestMe() {
    try {
      const me = await getMe()
      setMe(me)
    } catch (e) {
      console.error(e)
      const message = `エラーが発生しました\n${e.name || ""} ${e.message}`
      setRequestError(message)
    }
  }

  const theme = extendTheme({
    components: {
      Checkbox: {
        baseStyle: {
          borderColor: "black",
          control: {
            bg: "white",
            borderColor: "black",
          },
        },
      },
    },
  })

  const handleOnCloseAlert = useCallback(() => {
    props.history.goBack()
    setIsCompletedAlertOpen(false)
  }, [props.history])

  const handleOnClickUpdateAllAfter = (e) => {
    e.preventDefault()

    setIsUpdateAllAfter(!isUpdateAllAfter)
  }

  const handleOnClickChangeAddress = async () => {
    const selectedAddress = addresses.find(
      (address) => address.id === selectedId
    )
    if (!selectedAddress) return

    const body = Object.assign(
      {},
      {
        address_id: selectedAddress.id,
        zip: selectedAddress.zip,
        state: selectedAddress.state,
        city: selectedAddress.city,
        line1: selectedAddress.line1,
        line2: selectedAddress.line2,
        ship_from: selectedAddress.shipFrom,
        receiving_way: selectedAddress.receivingWay,
      }
    )

    try {
      if (isUpdateAllAfter) {
        await updateShippingAllAfter(shipping?.id || "", body)
      } else {
        await updateShipping(shipping?.id || "", body)
      }
      setIsCompletedAlertOpen(true)
    } catch (error) {
      if (error instanceof Response) {
        error.json().then((json) => {
          setSubmitError(
            json.messages?.join("\n") ||
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
          )
        })
      } else {
        setSubmitError("通信状態をお確かめの上、再度アクセスお願いします。")
      }
    }
  }

  const handleOnclickAddressNew = () => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "お届け先住所登録", me: me },
    })
  }

  function completedAlertDialog(message) {
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={undefined}
        onClose={handleOnCloseAlert}
        isOpen={true}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent m="12px">
          <AlertDialogHeader>{message}</AlertDialogHeader>
          <AlertDialogFooter>
            <Button onClick={handleOnCloseAlert}>OK</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  return requestError ? (
    <Text height="100vh">{requestError}</Text>
  ) : (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title="予定お届け先" />
      <Box p="16px" backgroundColor="white">
        {shipping && shipping.addressZip && (
          <Text textAlign="left" ml="60px">
            {shipping.addressZip}
            <br />
            {shipping.addressState}
            {shipping.addressCity}
            <br />
            {shipping.addressLine1}
            {shipping.addressLine2}
            <br />
            お届け先:
            {shipping.receivingWay ? Japanese[shipping.receivingWay] : ""}
            <br />
            発送元名:
            {shipping.shipFrom
              ? Japanese[shipping.shipFrom]
              : shipping.shipFrom}
          </Text>
        )}
        {shipping && !shipping.addressZip && (
          <Text textAlign="left" ml="60px">
            {prescription?.address.zip}
            <br />
            {prescription?.address.state}
            {prescription?.address.city}
            <br />
            {prescription?.address.line1}
            {prescription?.address.line2}
            <br />
            お届け先:
            {prescription?.receivingWay
              ? Japanese[prescription?.receivingWay]
              : ""}
            <br />
            発送元名:
            {prescription?.shipFrom ? Japanese[prescription?.shipFrom] : ""}
          </Text>
        )}
      </Box>
      <Text pt="24px" fontSize="20px" textAlign="center">
        お届け先変更希望がある場合
      </Text>
      <Text pb="40px" fontSize="20px" textAlign="center">
        希望の住所へチェックして下さい。
      </Text>
      <ChakraProvider theme={theme}>
        <CheckboxGroup colorScheme="orange">
          {addresses.map((address, i) => {
            return (
              <Box key={address.id}>
                {i !== 0 && <Divider />}
                <Box p="16px" backgroundColor="white">
                  <Checkbox
                    colorScheme="orange"
                    ml="16px"
                    size="lg"
                    isChecked={address.id === selectedId}
                    onChange={() => setSelectedId(address.id)}
                  >
                    <Text textAlign="left" mb="16px" ml="16px">
                      {address.zip}
                      <br />
                      {address.state}
                      {address.city}
                      <br />
                      {address.line1}
                      {address.line2}
                      <br />
                      お届け先:
                      {address.receivingWay
                        ? Japanese[address.receivingWay]
                        : ""}
                      <br />
                      発送元名:
                      {address.shipFrom ? Japanese[address.shipFrom] : ""}
                    </Text>
                  </Checkbox>
                </Box>
              </Box>
            )
          })}
        </CheckboxGroup>
      </ChakraProvider>

      <Box width="246px" m="12px auto">
        <ArrowButton
          iconColor="orange"
          iconHeight="28px"
          width="100%"
          height="64px"
          text={`新たなお届け先を\n追加する`}
          onClick={handleOnclickAddressNew}
        />
      </Box>

      <ChakraProvider theme={theme}>
        <Flex padding={2} justifyContent="center">
          <CheckboxGroup colorScheme="orange">
            <HStack>
              <Checkbox
                isChecked={isUpdateAllAfter}
                mt="32px"
                size="lg"
                colorScheme="orange"
                onClick={handleOnClickUpdateAllAfter}
              >
                <Text fontSize="20px" fontWeight="bold">
                  次回以降も全てこの住所へ
                  <br />
                  届ける場合チェックしてください
                </Text>
              </Checkbox>
            </HStack>
          </CheckboxGroup>
        </Flex>
      </ChakraProvider>
      <Box width="246px" m="0 auto 24px">
        <ArrowButton
          iconColor="orange"
          iconHeight="28px"
          width="100%"
          height="64px"
          text="変更する"
          onClick={() => handleOnClickChangeAddress()}
        />
      </Box>
      {isCompletedAlertOpen ? completedAlertDialog("変更が完了しました") : null}
      {submitError ? completedAlertDialog(submitError) : null}
    </Box>
  )
}

export default PrescriptionShippingEditPage
