import EntityType from "../models/EntityType"

export default class HistoryEntity implements EntityType {
  readonly zip: string | null
  readonly state: string | null
  readonly city: string | null
  readonly line1: string | null
  readonly line2: string | null
  readonly shipFrom: string | null
  readonly receivingWay: string | null
  readonly createdAt: string

  constructor(json: any) {
    this.zip = json["zip"]
    this.state = json["state"]
    this.city = json["city"]
    this.line1 = json["line1"]
    this.line2 = json["line2"]
    this.shipFrom = json["ship_from"]
    this.receivingWay = json["receiving_way"]
    this.createdAt = json["created_at"]
  }
}
