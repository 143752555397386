import EntityType from "../models/EntityType"
import DoctorEntity from "./DoctorEntity"

export default class MedicalExaminationEntity implements EntityType {
  readonly id: string
  readonly previousCounselingId: string | null
  readonly status: string
  readonly examinationType:
    | "examination"
    | "sideEffect"
    | "counseling_by_doctor"
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string
  readonly canceledAt: string
  readonly doneAt: string | null
  readonly doctor: DoctorEntity
  readonly hasCancelableSplitDispensingPrescription: boolean

  constructor(json: any) {
    this.id = json["id"]
    this.previousCounselingId = json["previous_counseling_id"]
    this.status = json["status"]
    this.examinationType = json["examination_type"]
    this.videoCallURL = json["video_call_url"]
    this.createdAt = json["created_at"]
    this.reservationAt = json["reservation_at"]
    this.canceledAt = json["canceled_at"]
    this.doneAt = json["done_at"]
    this.doctor = new DoctorEntity(json["doctor"])
    this.hasCancelableSplitDispensingPrescription =
      json["has_cancelable_split_dispensing_prescription"]
  }
}
