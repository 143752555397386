import { Box } from "@chakra-ui/react"
import React, { useCallback } from "react"
import ArrowButton from "./ArrowButton"
import MedicalExaminationActiveDetailComponent from "./MedicalExaminationActiveDetailComponent"
import MedicalExaminationDoneDetailComponent from "./MedicalExaminationDoneDetailComponent"
import * as H from "history"
import paths from "../helpers/ROUTES_PATH"
import MedicalExaminationsListModel from "../models/MedicalExaminationsListModel"
import CounselingDoneDetailComponent from "./CounselingDoneDetailComponent"

type Props = {
  list: MedicalExaminationsListModel | null | undefined
  shouldShowMoreButton: boolean
  customerId: string | undefined
  history: H.History
}

const MedicalExaminationsListSection: React.FC<Props> = (props: Props) => {
  const handleOnClickMoreButton = useCallback(() => {
    props.history.push({
      pathname: paths.medicalExaminations,
      state: { customerId: props.customerId },
    })
  }, [props.customerId, props.history])

  return (
    <>
      <Box height="40px" />
      {props.list?.medicalExaminations.map((m) => {
        return m.isStatusActive() ? (
          <MedicalExaminationActiveDetailComponent
            medicalExamination={m}
            customerID={props.customerId}
            key={m.id}
            history={props.history}
          />
        ) : (
          <MedicalExaminationDoneDetailComponent
            medicalExamination={m}
            key={m.id}
          />
        )
      })}
      {props.list?.counseling ? (
        <CounselingDoneDetailComponent counseling={props.list.counseling} />
      ) : null}
      {props.shouldShowMoreButton ? (
        <Box display="flex" justifyContent="center" p="0 27%">
          <ArrowButton
            icon={null}
            iconColor="orange"
            iconHeight={null}
            width="100%"
            height="64px"
            text="もっと見る"
            onClick={handleOnClickMoreButton}
            linkTarget={false}
          />
        </Box>
      ) : null}
    </>
  )
}

export default MedicalExaminationsListSection
