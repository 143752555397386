import ModelType from "./ModelType"
import TopInfoEntity from "../entities/TopInfoEntity"
import MeModel from "./MeModel"
import CounselingModel from "./CounselingModel"
import MedicalExaminationModel from "./MedicalExaminationModel"
import UnableToPrescribeMedicalExaminationModel from "./UnableToPrescribeMedicalExaminationModel"
import PrescriptionModel from "./PrescriptionModel"
import paths from "../helpers/ROUTES_PATH"
import MomentUtility from "../helpers/MomentUtility"

export const enum TopInfoStatusType {
  beforeCounseling = "before_counseling", // カウンセリング前（初期ユーザー）
  counselingAppointment = "counseling_appointment", // カウンセリング予約あり
  beforeMedicalExamination = "before_medical_examination", // 診察予約前
  medicalExaminationAppointment = "medical_examination_appointment", // 診察予約あり
  unableToPrescribeMedicalExaminationAppointment = "unable_to_prescribe_medical_examination_appointment", // 再診処方不可予約あり
  beforePurchase = "before_purchase", // 購入前
  purchaseFailed = "purchase_failed", // 購入失敗
  prescriptionExpired = "prescription_expired", // 処方箋有効期限切れ
  preparingShipping = "preparing_shipping", // 発送準備中
  shipped = "shipped", // 発送済み
  inTreatment = "in_treatment", // 治療中
}

// TODO: 単体テスト
export default class TopInfoModel implements ModelType {
  readonly status: TopInfoStatusType
  readonly customerID: string
  readonly counseling: CounselingModel | null
  readonly medicalExamination: MedicalExaminationModel | null
  readonly unableToPrescribeMedicalExamination: UnableToPrescribeMedicalExaminationModel | null
  readonly prescription: PrescriptionModel | null

  constructor(entity: TopInfoEntity) {
    this.status = entity.infoStatus as TopInfoStatusType
    this.customerID = entity.customerID || ""
    this.counseling = entity.counseling
      ? new CounselingModel(entity.counseling)
      : null
    this.medicalExamination = entity.medicalExamination
      ? new MedicalExaminationModel(entity.medicalExamination)
      : null
    this.unableToPrescribeMedicalExamination = entity.unableToPrescribeMedicalExamination
      ? new UnableToPrescribeMedicalExaminationModel(
          entity.unableToPrescribeMedicalExamination
        )
      : null
    this.prescription = entity.prescription
      ? new PrescriptionModel(entity.prescription)
      : null
  }

  doctorMessage(params: { me: MeModel | undefined }): string {
    const { me } = params
    const name = `${me?.fullName}様`

    if (this.status === TopInfoStatusType.beforeCounseling) {
      return "まずは\n初回カウンセリングを\nご予約ください。"
    } else if (this.status === TopInfoStatusType.counselingAppointment) {
      return `${name}、\n初回カウンセリングは\n${this.reservationDatetime()}です。`
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return `${name}、\n次回の診療は\n${this.reservationDatetime()}です。`
    } else if (this.status === TopInfoStatusType.beforeMedicalExamination) {
      return `${name}、お薬の処方には医師との診療が必要です。\n下記からご予約ください。`
    } else if (this.status === TopInfoStatusType.beforePurchase) {
      return `${name}、\n医師がお薬を処方しました。\n購入ボタンからお薬をご購入ください。`
    } else if (this.status === TopInfoStatusType.purchaseFailed) {
      return `${name}、\n決済に失敗いたしました。\n再度購入ボタンからお薬を14日以内にご購入ください。`
    } else if (this.status === TopInfoStatusType.prescriptionExpired) {
      return `${name}、前回処方箋の有効期限が切れました。\nお手数ですが、再度医師との診療予約をおとりください`
    } else if (this.status === TopInfoStatusType.preparingShipping) {
      return `${name}、\nお薬を発送準備中です。\n発送次第お知らせ致します。`
    } else if (this.status === TopInfoStatusType.shipped) {
      return `${name}、下記のお薬を発送手配いたしました。\n到着まで今しばらくお待ち下さい。`
    } else if (this.status === TopInfoStatusType.inTreatment) {
      if (
        this.prescription?.isSplitDispensing &&
        this.prescription.splitDispensingTimes !==
          this.prescription.totalSplitDispensingTimes
      ) {
        return `お手元のお薬がなくなる\n3日前から次回のお支払いが可能になります。`
      } else {
        const estimateDate = this.nextExpectedMedicalExamination()
        return `お手元のお薬がなくなる\n10日前を目処に次回の診療をご予約ください。\n次の診療の目安は\n${estimateDate}です。`
      }
    } else {
      console.error("unknown customer status")
      return ""
    }
  }

  doctorMessageBoldPart(): string | null {
    if (
      this.status === TopInfoStatusType.inTreatment &&
      !this.prescription?.isSplitDispensing
    ) {
      return this.nextExpectedMedicalExamination()
    } else {
      return null
    }
  }

  reservationDatetime(): string | null {
    if (this.status === TopInfoStatusType.counselingAppointment) {
      return this.counseling?.reservationTimeWithoutYear() || null
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment
    ) {
      return this.medicalExamination?.reservationTimeWithoutYear() || null
    } else if (
      this.status ===
      TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return (
        this.unableToPrescribeMedicalExamination?.reservationTimeWithoutYear() ||
        null
      )
    } else if (
      this.status === TopInfoStatusType.beforeCounseling ||
      this.status === TopInfoStatusType.beforeMedicalExamination ||
      this.status === TopInfoStatusType.prescriptionExpired ||
      this.status === TopInfoStatusType.inTreatment
    ) {
      return "未設定"
    } else {
      return null
    }
  }

  assignedUserType(): string | null {
    if (this.status === TopInfoStatusType.counselingAppointment) {
      return "担当カウンセラー"
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return "担当医師"
    } else {
      return null
    }
  }

  assignedUserName(): string | null {
    if (this.status === TopInfoStatusType.counselingAppointment) {
      return this.counseling?.counselor?.name || null
    } else {
      return null
    }
  }

  actionButtonURL(customerID: string): string | null {
    if (this.status === TopInfoStatusType.beforeCounseling) {
      return `${paths.customerScheduleCounselings}/${customerID}`
    } else if (this.status === TopInfoStatusType.counselingAppointment) {
      return `${paths.customerScheduleCounselingsOverwrite}/${this.counseling?.id}/${customerID}`
    } else if (this.status === TopInfoStatusType.beforeMedicalExamination) {
      return `${paths.customerScheduleMedicalExamination}/${customerID}`
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment
    ) {
      if (this.medicalExamination) {
        // 20分診療（医師によるカウンセリング）ならばカウンセリングの予約変更ページに遷移させる
        const path =
          this.medicalExamination.examinationType === "counseling_by_doctor"
            ? paths.customerScheduleCounselingsOverwrite
            : paths.customerScheduleMedicalExaminationOverwrite
        const id =
          this.medicalExamination.examinationType === "counseling_by_doctor"
            ? this.medicalExamination.previousCounselingId!
            : this.medicalExamination.id
        return `${path}/${id}/${customerID}`
      } else {
        // statusがmedicalExaminationAppointmentならばmedicalExaminationは必ず存在するはず
        return ""
      }
    } else if (
      this.status ===
      TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return `${paths.customerScheduleUnableToPrescribeMedicalExaminationOverwrite}/${this.unableToPrescribeMedicalExamination?.id}/${customerID}`
    } else if (
      this.status === TopInfoStatusType.beforePurchase &&
      this.prescription?.price === 0
    ) {
      return this.prescription?.prescriptionURL
        ? `${this.prescription?.prescriptionURL}/trial`
        : null
    } else if (this.status === TopInfoStatusType.beforePurchase) {
      return this.prescription?.prescriptionURL || null
    } else if (this.status === TopInfoStatusType.purchaseFailed) {
      return this.prescription?.prescriptionURL || null
    } else if (this.status === TopInfoStatusType.prescriptionExpired) {
      return `${paths.customerScheduleMedicalExamination}/${customerID}`
    } else if (this.status === TopInfoStatusType.preparingShipping) {
      return null // no button for preparingShipping
    } else if (this.status === TopInfoStatusType.shipped) {
      return this.prescription?.kuronekoTrackigURL()
        ? this.prescription.kuronekoTrackigURL()
        : null
    } else if (this.status === TopInfoStatusType.inTreatment) {
      // NOTE: 分割調剤の場合、最後の処方でない限り予約ボタンを表示しない
      if (
        this.prescription?.isSplitDispensing &&
        !this.prescription?.isLastSplitDispensing
      )
        return null

      return `${paths.customerScheduleMedicalExamination}/${customerID}`
    } else {
      console.error("unknown customer status")
      return null
    }
  }

  actionButtonIcon(): string | null {
    if (this.status === TopInfoStatusType.beforePurchase) {
      return "cart"
    } else if (this.status === TopInfoStatusType.purchaseFailed) {
      return "cart"
    } else if (this.status === TopInfoStatusType.shipped) {
      return "magnifier"
    } else if (this.status === TopInfoStatusType.preparingShipping) {
      return null
    } else {
      return "calendar"
    }
  }

  actionButtonText(): string | null {
    if (this.status === TopInfoStatusType.beforeCounseling) {
      return "カウンセリング予約をする"
    } else if (this.status === TopInfoStatusType.counselingAppointment) {
      return "カウンセリング予約の変更"
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return "診療予約を変更する"
    } else if (this.status === TopInfoStatusType.beforeMedicalExamination) {
      return "診療予約をする"
    } else if (
      this.status === TopInfoStatusType.beforePurchase &&
      this.prescription?.price === 0
    ) {
      return "お薬配送の手続きを行う"
    } else if (this.status === TopInfoStatusType.beforePurchase) {
      return "お薬を購入する"
    } else if (this.status === TopInfoStatusType.purchaseFailed) {
      return "お薬を購入する"
    } else if (this.status === TopInfoStatusType.prescriptionExpired) {
      return "予約をする"
    } else if (this.status === TopInfoStatusType.preparingShipping) {
      return null // no button for preparingShipping
    } else if (this.status === TopInfoStatusType.shipped) {
      return "配送状況を確認する"
    } else if (this.status === TopInfoStatusType.inTreatment) {
      return "診療予約をする"
    } else {
      return null
    }
  }

  talkButtonURL(): string | null {
    if (this.status === TopInfoStatusType.counselingAppointment) {
      return this.counseling?.videoCallURL || null
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment
    ) {
      return this.medicalExamination?.videoCallURL || null
    } else if (
      this.status ===
      TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return this.unableToPrescribeMedicalExamination?.videoCallURL || null
    } else {
      return null
    }
  }

  deliveringMedicines(): string[] | null {
    if (
      this.status === TopInfoStatusType.shipped &&
      this.prescription?.prescriptionDetails
    ) {
      return this.prescription?.prescriptionDetails.map(
        (prescriptionDetail) => {
          if (prescriptionDetail.medicineNameForCustomer) {
            return prescriptionDetail.medicineNameForCustomer
          } else {
            return `${prescriptionDetail.medicineName}${prescriptionDetail.spec}`
          }
        }
      )
    } else {
      return null
    }
  }

  trackingNumber(): string | null {
    if (this.status === TopInfoStatusType.shipped) {
      return this.prescription?.latestTrackingNumber()
        ? this.prescription?.latestTrackingNumber()
        : null
    } else {
      return null
    }
  }

  deliveryCaution(): string | null {
    return this.status === TopInfoStatusType.shipped
      ? "ヤマト運輸による集荷に1~3日を要するため、追跡番号未登録と表示されてしまうことがあります。"
      : null
  }

  talkButtonText(): string | null {
    if (this.status === TopInfoStatusType.counselingAppointment) {
      return "カウンセリング\nを開始する"
    } else if (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return "診療を開始する"
    } else {
      return ""
    }
  }

  talkButtonCaution(): string | null {
    if (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    ) {
      return "医師とのビデオ通話へとつながります。必ず診療時間になってからクリックしてください。"
    } else if (this.status === TopInfoStatusType.counselingAppointment) {
      return "カウンセラーとのビデオ通話へとつながります。必ずカウンセリング時間になってからクリックしてください。"
    } else {
      return null
    }
  }

  talkButtonCautionBoldPart(): string | null {
    return this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
      ? "診療時間になってから"
      : null
  }

  medicinesPicture(): string | null {
    return this.status === TopInfoStatusType.beforePurchase ||
      this.status === TopInfoStatusType.purchaseFailed
      ? "assets/medicines_picture.png"
      : null
  }

  shouldHaveTopAndBottomMargins(): boolean {
    return this.status !== TopInfoStatusType.preparingShipping
  }

  shouldShowMedicinesPicture(): boolean {
    return (
      this.status === TopInfoStatusType.beforePurchase ||
      this.status === TopInfoStatusType.purchaseFailed
    )
  }

  shouldShowDeliveryCaution(): boolean {
    return this.status === TopInfoStatusType.shipped
  }

  shouldShowDoctorInfo(): boolean {
    return (
      this.status === TopInfoStatusType.medicalExaminationAppointment ||
      this.status ===
        TopInfoStatusType.unableToPrescribeMedicalExaminationAppointment
    )
  }

  private nextExpectedMedicalExamination(): string | null {
    if (!this.prescription || this.status !== TopInfoStatusType.inTreatment) {
      return null
    } else {
      if (!this.prescription.runOutDate) {
        return null
      }

      const estimatedEnd = new MomentUtility(this.prescription.runOutDate)
      const date = estimatedEnd.subtractDays(10)
      return date ? `${date.month()}月${date.date()}日頃` : null
    }
  }
}
