import { CSSReset, Text, Box } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import PageTitleSection from "../../ui-components/PageTitleSection"
import { requestAllMedicalExaminations } from "./MedicalExaminationsPageController"
import MedicalExaminationsListSection from "../../ui-components/MedicalExaminationsListSection"
import MedicalExaminationsListModel from "../../models/MedicalExaminationsListModel"
import { useLocation } from "react-router"

type Props = {
  history: H.History
}

const MedicalExaminationsPage: React.FC<Props> = (props: Props) => {
  const [customerId, setCustomerId] = useState<string>()
  const location = useLocation()

  const [
    medicalExaminations,
    setMedicalExaminations,
  ] = useState<MedicalExaminationsListModel>()
  const [requestError, setRequestError] = useState<string | null>()

  useEffect(() => {
    prepareMedicalExaminations()
    const state = location.state as Object
    const id = state["customerId"] as string
    setCustomerId(id)
    window.scrollTo(0, 0)
  }, [location.state])

  async function prepareMedicalExaminations() {
    try {
      setMedicalExaminations(await requestAllMedicalExaminations())
      setRequestError(null)
    } catch (e) {
      console.error(e)
      const message = `エラーが発生しました\n${e.name || ""} ${e.message}`
      setRequestError(message)
    }
  }

  return requestError ? (
    <Text height="100vh">{requestError}</Text>
  ) : (
    <>
      <CSSReset />
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title="診療履歴" />
      <MedicalExaminationsListSection
        list={medicalExaminations}
        customerId={customerId}
        shouldShowMoreButton={false}
        history={props.history}
      />
      <Box height="80px" />
    </>
  )
}

export default MedicalExaminationsPage
