import ModelType from "./ModelType"
import AddressModel from "./AddressModel"
import HistoryModel from "./HistoryModel"
import AddressListEntity from "../entities/AddressListEntity"

export default class AddressListModel implements ModelType {
  readonly addresses: AddressModel[]
  readonly updateHistories: HistoryModel[]

  constructor(entity: AddressListEntity) {
    this.addresses = entity.addresses.map(
      (address) => new AddressModel(address)
    )
    this.updateHistories = entity.updateHistories.map(
      (history) => new HistoryModel(history)
    )
  }
}
