import { Box, Text } from "@chakra-ui/react"
import React from "react"
import MedicalExaminationModel from "../models/MedicalExaminationModel"
import CustomerHomeTalkButtonSection from "../pages/CustomerHome/ActionView/CustomerHomeTalkButtonSection"
import { TenetColors } from "../ui-helpers/ChakraTheme"
import ArrowButton from "./ArrowButton"
import ReservationDatetimeSection from "./ReservationDatetimeSection"
import * as H from "history"
import DoctorInfoComponent from "./DoctorInfoComponent"
import paths from "../helpers/ROUTES_PATH"

type Props = {
  medicalExamination: MedicalExaminationModel
  customerID: string | undefined
  history: H.History
}

const MedicalExaminationActiveDetailComponent: React.FC<Props> = (
  props: Props
) => {
  const { medicalExamination, history } = props

  function headerComponent() {
    return (
      <Box
        bg={TenetColors.orange.transparent}
        p="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="16px">診療</Text>
        <Box p="2px 8px" bg={TenetColors.orange._200} width="72px">
          <Text
            fontSize="14px"
            textColor={TenetColors.white._100}
            textAlign="center"
          >
            予約済
          </Text>
        </Box>
      </Box>
    )
  }

  function reservationInfoComponent() {
    const path =
      medicalExamination.examinationType === "counseling_by_doctor"
        ? paths.customerScheduleCounselingsOverwrite
        : paths.customerScheduleMedicalExaminationOverwrite
    const id =
      medicalExamination.examinationType === "counseling_by_doctor"
        ? medicalExamination.previousCounselingId!
        : medicalExamination.id

    return (
      <Box m="12px 0">
        <ReservationDatetimeSection
          datetime={medicalExamination.reservationTimeWithYear() || ""}
        />
        <Box display="flex" justifyContent="center" p="0 2%">
          <ArrowButton
            icon="calendar"
            iconColor="orange"
            iconHeight="28px"
            width="100%"
            height="64px"
            text="予約の変更"
            link={`${path}/${id}/${props.customerID}`}
            linkTarget={true}
          />
        </Box>
      </Box>
    )
  }

  function doctorInfoComponent() {
    return (
      <DoctorInfoComponent
        doctor={medicalExamination.doctor}
        history={history}
      />
    )
  }

  return (
    <Box
      mb="16px"
      border={`2px solid ${TenetColors.orange._200}`}
      bg={TenetColors.white._100}
    >
      {headerComponent()}
      {reservationInfoComponent()}
      <CustomerHomeTalkButtonSection
        url={medicalExamination.videoCallURL}
        text="診療を開始する"
        caution="医師とのビデオ通話へとつながります。必ず診療時間になってからクリックしてください。"
        cautionBoldPart="診療時間になってから"
      />
      {doctorInfoComponent()}
      <Box height="16px" />
    </Box>
  )
}

export default MedicalExaminationActiveDetailComponent
