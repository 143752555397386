import EntityType from "../models/EntityType"

export default class DoctorEntity implements EntityType {
  readonly doctorID: string
  readonly name: string
  readonly email: string
  readonly meetInURL: string | null
  readonly phone: string | null
  readonly career: string | null
  readonly registrationDate: string | null
  readonly commentFromDoctor: string | null
  readonly profileImageURL: string | null
  readonly certificateImageURL: string | null
  readonly doctorForFirst: boolean | null
  readonly sideEffectExamination: boolean | null
  readonly immediateExamination: boolean | null

  constructor(json: any) {
    this.doctorID = json["doctor_id"]
    this.name = json["name"]
    this.email = json["email"]
    this.meetInURL = json["meet_in"]
    this.phone = json["phone"]
    this.career = json["career"]
    this.registrationDate = json["registration_date"]
    this.commentFromDoctor = json["comment_from_doctor"]
    this.profileImageURL = json["profile_image_url"]
    this.certificateImageURL = json["certificate_image_url"]
    this.doctorForFirst = json["doctor_for_first"]
    this.sideEffectExamination = json["side_effect_examination"]
    this.immediateExamination = json["immediate_examination"]
  }
}
