import React, { useCallback, useEffect, useState } from "react"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import { useLocation } from "react-router-dom"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import PageTitleSection from "../../ui-components/PageTitleSection"
import * as H from "history"
import ArrowButton from "../../ui-components/ArrowButton"
import {
  Box,
  Button,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  Alert,
  AlertDescription,
} from "@chakra-ui/react"
import MeModel from "../../models/MeModel"
import AddressModel from "../../models/AddressModel"
import HistoryModel from "../../models/HistoryModel"
import paths from "../../helpers/ROUTES_PATH"
import { usingAddress, getAddresses } from "../../api-helpers/APICalls"
import { Japanese } from "../../../public/components/PrescriptionPage/types"

type Props = {
  history: H.History
}

const AddressesEditPage: React.FC<Props> = (props: Props) => {
  const [addresses, setAddresses] = useState<AddressModel[]>([])
  const [histories, setHistories] = useState<HistoryModel[]>([])
  const [me, setMe] = useState<MeModel>()
  const location = useLocation()
  const [isCompletedAlertOpen, setIsCompletedAlertOpen] = useState<boolean>(
    false
  )
  const [errorMessage, setErrorMessage] = useState<boolean>(false)

  useEffect(() => {
    const state = location.state as Object
    const m = state["me"] as MeModel
    setMe(m)
    fetchAddress()
  }, [location.state])

  const fetchAddress = async () => {
    const addressList = await getAddresses()
    const addresses = addressList.addresses
    const histories = addressList.updateHistories

    setAddresses(addresses)
    setHistories(histories)
  }

  const handleOnClickAddressChange = (address) => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "お届け先住所", me: me, address: address },
    })
  }

  const handleOnClickUseAddress = async (address) => {
    try {
      await usingAddress(address.id)
      setIsCompletedAlertOpen(true)
    } catch (res) {
      res.json().then((body) => {
        setErrorMessage(body.messages.join("\n"))
      })
    }
  }

  const handleOnCloseAlert = useCallback(() => {
    props.history.goBack()
    setIsCompletedAlertOpen(false)
  }, [props.history])

  const handleOnclickAddressNew = () => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "お届け先住所登録", me: me },
    })
  }

  const completedAlertDialog = () => {
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={undefined}
        onClose={handleOnCloseAlert}
        isOpen={true}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent m="12px">
          <AlertDialogHeader>変更が完了しました</AlertDialogHeader>
          <AlertDialogFooter>
            <Button onClick={handleOnCloseAlert}>OK</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  return (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title={"お届け先住所設定"} />

      <div className="d-flex flex-column px-2">
        {errorMessage && (
          <Alert status="error">
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        {addresses.map((address, i) => {
          return (
            <div key={i}>
              <Box
                display="flex"
                minH="118px"
                bg={TenetColors.white._100}
                justifyContent="space-between"
                alignItems="center"
                p="24px"
              >
                <Box>
                  {address.zip}
                  <br />
                  {address.state}
                  {address.city}
                  <br />
                  {address.line1}
                  {address.line2}
                  <br />
                  {`お届け先: ${
                    address.receivingWay ? Japanese[address.receivingWay] : ""
                  }`}
                  <br />
                  {`発送元名: ${
                    address.shipFrom ? Japanese[address.shipFrom] : ""
                  }`}
                </Box>
                <Box height="6px" />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <ArrowButton
                    width="107px"
                    height="60px"
                    text="変更"
                    iconColor="orange"
                    icon={null}
                    iconHeight={null}
                    onClick={() => handleOnClickAddressChange(address)}
                    linkTarget={false}
                  />
                  {!address.default && (
                    <Box mt="3" whiteSpace="pre">
                      <ArrowButton
                        width="160px"
                        height="70px"
                        text={`規定の\n住所に設定`}
                        iconColor="orange"
                        icon={null}
                        iconHeight={null}
                        onClick={() => handleOnClickUseAddress(address)}
                        linkTarget={false}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider />
            </div>
          )
        })}
      </div>
      <Box m="32px 20%">
        <ArrowButton
          width="100%"
          height="64px"
          text="新しく住所を登録"
          iconColor="orange"
          onClick={handleOnclickAddressNew}
        />
      </Box>

      <PageTitleSection title={"変更履歴"} />

      <Box height="100%" bg={TenetColors.white._100}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>変更項目</th>
              <th>作成日時</th>
            </tr>
          </thead>
          <tbody>
            {histories.map((history, i) => (
              <tr key={i}>
                <td>
                  <ul style={{ listStyle: "none" }}>
                    {Object.entries(history).map(([attribute, values], idx) => {
                      if (!Array.isArray(values)) {
                        return null
                      }

                      let [oldValue, newValue] = values
                      if (
                        attribute === "shipFrom" ||
                        attribute === "receivingWay"
                      ) {
                        oldValue = Japanese[oldValue]
                        newValue = Japanese[newValue]
                      }

                      return (
                        <li key={idx}>
                          {Japanese[attribute]}
                          {" : "}
                          {oldValue !== null && oldValue !== undefined
                            ? oldValue + " => "
                            : ""}
                          {newValue !== null && newValue !== undefined
                            ? newValue
                            : "null"}
                        </li>
                      )
                    })}
                  </ul>
                </td>
                <td>{history.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      {isCompletedAlertOpen ? completedAlertDialog() : null}
    </Box>
  )
}

export default AddressesEditPage
