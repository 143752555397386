import { Image, HStack, Box } from "@chakra-ui/react"
import React from "react"
import MeModel from "../../models/MeModel"
import TopInfoModel from "../../models/TopInfoModel"
import images from "../../ui-helpers/ImageSources"
import GradationDivider from "../GradationDivider"
import MessageBubble from "../MessageBubble"

type Props = {
  topInfo: TopInfoModel | undefined
  me: MeModel | undefined
}

const CustomerHomeDoctorMessageView: React.FC<Props> = (props: Props) => {
  const { me, topInfo } = props

  const doctorMessage = topInfo?.doctorMessage({ me: me }) || " ".repeat(30)
  const isDoctorMessageTooLong = doctorMessage
    ? doctorMessage.length > 40
    : false

  return (
    <Box>
      <Box height="24px" />
      <HStack width="100%" align="top" mb={isDoctorMessageTooLong ? 18 : 0}>
        <Image
          width="30%"
          height="48%"
          src={images.doctorIllustration}
          alt="医者"
          fallback={<Box w="130px" />}
        />
        <MessageBubble
          message={doctorMessage}
          boldPart={topInfo?.doctorMessageBoldPart()}
        />
      </HStack>
      <Box height="24px" />
      <GradationDivider />
    </Box>
  )
}

export default CustomerHomeDoctorMessageView
