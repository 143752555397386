import EntityType from "../models/EntityType"
import CounselingEntity from "./CounselingEntity"
import MedicalExaminationEntity from "./MedicalExaminationEntity"
import UnableToPrescribeMedicalExaminationEntity from "./UnableToPrescribeMedicalExaminationEntity"
import PrescriptionEntity from "./PrescriptionEntity"

export default class TopInfoEntity implements EntityType {
  readonly infoStatus: string | null
  readonly customerID: string | null
  readonly counseling: CounselingEntity | null
  readonly medicalExamination: MedicalExaminationEntity | null
  readonly unableToPrescribeMedicalExamination: UnableToPrescribeMedicalExaminationEntity | null
  readonly prescription: PrescriptionEntity | null

  constructor(json: any) {
    const topInfo = json["top_infos"]
    this.infoStatus = topInfo["info_status"]
    this.customerID = topInfo["customer_id"]
    this.counseling = topInfo["counseling"]
      ? new CounselingEntity(topInfo["counseling"])
      : null
    this.medicalExamination = topInfo["medical_examination"]
      ? new MedicalExaminationEntity(topInfo["medical_examination"])
      : null
    this.unableToPrescribeMedicalExamination = topInfo[
      "unable_to_prescribe_medical_examination"
    ]
      ? new UnableToPrescribeMedicalExaminationEntity(
          topInfo["unable_to_prescribe_medical_examination"]
        )
      : null
    this.prescription = topInfo["prescription"]
      ? new PrescriptionEntity(topInfo["prescription"])
      : null
  }
}
