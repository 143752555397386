import PrescriptionShippingEntity from "../entities/PrescriptionShippingEntity"
import ModelType from "./ModelType"

export default class PrescriptionShippingModel implements ModelType {
  readonly id: string
  readonly prescriptionID: string
  readonly status: string
  readonly code: string
  readonly deliveryStartAt: string
  readonly deliveredAt: string | null
  readonly divisionNumber: number | null
  readonly trackingNumber: string | null
  readonly addressCity: string | null
  readonly addressLine1: string | null
  readonly addressLine2: string | null
  readonly addressState: string | null
  readonly addressZip: string | null
  readonly shipFrom: string | null
  readonly receivingWay: string | null

  constructor(entity: PrescriptionShippingEntity) {
    this.id = entity.id
    this.prescriptionID = entity.prescriptionID
    this.status = entity.status
    this.code = entity.code
    this.deliveryStartAt = entity.deliveryStartAt
    this.deliveredAt = entity.deliveredAt
    this.divisionNumber = entity.divisionNumber
    this.trackingNumber = entity.trackingNumber
    this.addressCity = entity.addressCity
    this.addressLine1 = entity.addressLine1
    this.addressLine2 = entity.addressLine2
    this.addressState = entity.addressState
    this.addressZip = entity.addressZip
    this.shipFrom = entity.shipFrom
    this.receivingWay = entity.receivingWay
  }
}
