import { Box, Text, Image } from "@chakra-ui/react"
import React from "react"
import { TenetColors } from "../../../ui-helpers/ChakraTheme"
import images from "../../../ui-helpers/ImageSources"

type Props = {
  caution: string
}

const CustomerHomeDeliveryCautionSection: React.FC<Props> = (props: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height="100px"
      mt="16px"
      pl="24px"
      pr="24px"
      bg={TenetColors.white._100}
    >
      <Image src={images.caution} height="48px" alt="ビックリマーク" />
      <Box width="32px" />
      <Box>
        <Text
          whiteSpace="pre-wrap"
          textAlign="left"
          lineHeight="22.5px"
          fontSize="15px"
        >
          {props.caution}
        </Text>
      </Box>
    </Box>
  )
}

export default CustomerHomeDeliveryCautionSection
