export enum PrescriptionStatusType {
  BeforePurchase = "before_purchase",
  Purchased = "purchased",
  PurchaseFailed = "purchase_failed",
  Delivered = "delivered",
  Canceled = "canceled",
  Expired = "expired",
}

export type Prescription = {
  key: string
  code: string
  created_at: Date
  purchased_at: Date
  status: PrescriptionStatusType
  amount: number
  payment_method: PaymentMethodType
  medical_examination_url: string
  customer: Customer
  address: Exclude<Address, "default">
  customer_bank_account?: BankAccount
  bank_transfer_deadline: string
  is_split_dispensing: boolean
  days: number
  prescription_details: Array<PrescriptionDetail>
}

export type Delivery = {
  receivingWay: ReceivingWay
  shipFrom: ShipFrom
  addressZip: string
  addressState: string
  addressCity: string
  addressLine1: string
  addressLine2: string
}

export type Customer = {
  address_zip: string
  address_state: string
  address_city: string
  address_line1: string
  address_line2: string
  name: string
}

export enum ReceivingWay {
  AtHome = "at_home",
  DeliveryCenterPickup = "delivery_center_pickup",
}

export enum ShipFrom {
  OzuClinic = "ozu_clinic",
  DrNakajima = "dr_nakajima",
  AgaSmartClinic = "aga_smart_clinic",
  SmartClinic = "smart_clinic",
  DrTashiro = "dr_tashiro",
  WellPartner = "well_partner",
}

export enum PaymentMethodType {
  CreditCard = "credit_card",
  DebitCard = "debit_card",
  PrepaidCard = "prepaid_card",
  BankTransfer = "bank_transfer",
  AmazonPay = "amazon_pay",
  Other = "other",
}

export const Japanese: { [key: string]: string } = {
  aga_smart_clinic: "AGAスマクリ（医療法人社団ウェルパートナー）",
  dr_tashiro: "田代友里子",
  well_partner: "医療法人社団ウェルパートナー",
  at_home: "ご自宅",
  delivery_center_pickup: "ヤマト運輸営業所",
  ozu_clinic: "オズクリニック",
  dr_nakajima: "中嶋真由子",
  smart_clinic: "スマートクリニック",
  zip: "郵便番号",
  state: "都道府県",
  city: "市区町村 町名",
  line1: "番地",
  line2: "建物・部屋番号・その他",
  receivingWay: "お受け取り方法",
  shipFrom: "発送元名",
}

export type BankAccount = {
  bank_name: string
  branch_name: string
  account_number: string
}

export type PaymentMethod = {
  paymentMethod: PaymentMethodType
  number: string
  name: string
  expMonth: string
  expYear: string
  cvc: string
  sequence: string
  amazonCheckoutSessionId: null | string
}

export type Card = {
  card_number: string
  expire: string
  holder_name: string
  sequence: string
}

export type Address = {
  address_zip: string
  address_state: string
  address_city: string
  address_line1: string
  address_line2: string
  receiving_way: ReceivingWay
  ship_from: ShipFrom
  default: ReceivingWay
}

export type ValidationError = { [key: string]: string }

export type PrescriptionDetail = {
  day: number | null
  dosage_per_day: string | null
  medicine_name: string
  medicine_name_for_customer: string | null
  month: number | null
  spec: string
}
