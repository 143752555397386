import CounselorEntity from "../entities/CounselorEntity"
import ModelType from "./ModelType"

export default class CounselorModel implements ModelType {
  readonly name: string
  readonly email: string
  readonly meetInURL: string | null
  readonly phoneNumber: string | null

  constructor(entity: CounselorEntity) {
    this.name = entity.name
    this.email = entity.email
    this.meetInURL = entity.meetIn || null
    this.phoneNumber = entity.phone || null
  }
}
