import DoctorEntity from "../entities/DoctorEntity"
import ModelType from "./ModelType"

export default class DoctorModel implements ModelType {
  readonly id: string
  readonly name: string
  readonly email: string
  readonly meetInURL: string | null
  readonly phoneNumber: string | null
  readonly career: string | null
  readonly registrationDate: string | null
  readonly commentFromDoctor: string | null
  readonly profileImageURL: string | null
  readonly certificateImageURL: string | null

  constructor(entity: DoctorEntity) {
    this.id = entity.doctorID
    this.name = entity.name
    this.email = entity.email
    this.meetInURL = entity.meetInURL
    this.phoneNumber = entity.phone
    this.career = entity.career
    this.registrationDate = entity.registrationDate
    this.commentFromDoctor = entity.commentFromDoctor
    this.profileImageURL = entity.profileImageURL
    this.certificateImageURL = entity.certificateImageURL
  }
}
