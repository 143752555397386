import {
  Text,
  Flex,
  Box,
  Input,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Select,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import ArrowButton from "../../ui-components/ArrowButton"
import PageTitleSection from "../../ui-components/PageTitleSection"
import { useLocation } from "react-router-dom"
import { updateMe } from "./CustomerDetailChangePageController"
import { updateAddress, createAddress } from "../../api-helpers/APICalls"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import MeModel from "../../models/MeModel"
import AddressModel from "../../models/AddressModel"
import ZipCode from "../../../utils/zipcode"

type ChangeTarget =
  | "お名前"
  | "お届け先住所"
  | "お届け先住所登録"
  | "お電話番号"
  | "メールアドレス"
  | "支払い方法"
  | "自動決済"
type InputType =
  | "name"
  | "phone"
  | "email"
  | "zip"
  | "state"
  | "city"
  | "line1"
  | "line2"
  | "receivingWay"
  | "shipFrom"
  | "paymentMethod"
  | "use_auto_payment"
  | "autoPaymentTimes"

// using snake_case for JSON object
type AddressBody = {
  id: number
  address_zip: string
  address_state: string
  address_city: string
  address_line1: string
  address_line2: string
  ship_from: string
  receiving_way: string
}

type Props = {
  history: H.History
}

const CustomerDetailChangePage: React.FC<Props> = (props: Props) => {
  const [changeTarget, setChangeTarget] = useState<ChangeTarget>()
  const [requestError, setRequestError] = useState<string | null>()
  const [invalidTypes, setInvalidTypes] = useState<InputType[]>([])
  const [name, setName] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [paymentMethod, setPaymentMethod] = useState<string>()
  const [address, setAddress] = useState<AddressBody>({
    id: 0,
    address_zip: "",
    address_state: "",
    address_city: "",
    address_line1: "",
    address_line2: "",
    receiving_way: "",
    ship_from: "",
  })
  const [useAutoPayment, setUseAutoPayment] = useState<boolean>(false)
  const [autoPaymentTimes, setAutoPaymentTimes] = useState<string | null>(null)
  const [isCompletedAlertOpen, setIsCompletedAlertOpen] = useState<boolean>(
    false
  )
  const location = useLocation()

  useEffect(() => {
    const state = location.state as Object
    const t = state["changeTarget"] as ChangeTarget
    setChangeTarget(t)
    const m = state["me"] as MeModel
    const address = state["address"] as AddressModel
    setStartingMeAddress(address)
    if (m.paymentMethod) setPaymentMethod(m.paymentMethod)
    setUseAutoPayment(m.useAutoPayment || false)
    const autoPaymentTimes = m.autoPaymentTimes
    setAutoPaymentTimes(autoPaymentTimes?.toString() ?? autoPaymentTimes)
  }, [location.state])

  function setStartingMeAddress(address: AddressModel) {
    setAddress({
      id: address?.id || 0,
      address_zip: address?.zip || "",
      address_state: address?.state || "",
      address_city: address?.city || "",
      address_line1: address?.line1 || "",
      address_line2: address?.line2 || "",
      receiving_way: address?.receivingWay || "",
      ship_from: address?.shipFrom || "",
    })
  }

  const createRequestBody = useCallback(() => {
    if (changeTarget === "お名前") {
      return { name: name }
    } else if (changeTarget === "お電話番号") {
      return { phone: phone }
    } else if (changeTarget === "メールアドレス") {
      return { email: email }
    } else if (
      changeTarget === "お届け先住所" ||
      changeTarget === "お届け先住所登録"
    ) {
      const object = Object.assign(
        {},
        {
          zip: address.address_zip,
          state: address.address_state,
          city: address.address_city,
          line1: address.address_line1,
          line2: address.address_line2,
          ship_from: address.ship_from,
          receiving_way: address.receiving_way,
        }
      )
      return object
    } else if (changeTarget === "支払い方法") {
      return { payment_method: paymentMethod }
    } else if (changeTarget === "自動決済") {
      return {
        use_auto_payment: useAutoPayment,
        auto_payment_times: autoPaymentTimes,
      }
    }
  }, [
    address,
    changeTarget,
    email,
    name,
    phone,
    paymentMethod,
    useAutoPayment,
    autoPaymentTimes,
  ])

  const invalidInputs = useCallback(() => {
    if (changeTarget === "お名前") {
      return name !== null && name !== undefined && name.length > 0
        ? []
        : ["name"]
    } else if (changeTarget === "お電話番号") {
      return phone !== null && phone !== undefined && phone.length > 0
        ? []
        : ["phone"]
    } else if (changeTarget === "メールアドレス") {
      return email !== null && email !== undefined && email.length > 0
        ? []
        : ["email"]
    } else if (
      changeTarget === "お届け先住所" ||
      changeTarget === "お届け先住所登録"
    ) {
      const invalid: InputType[] = []
      if (!address.address_zip) {
        invalid.push("zip")
      }
      if (!address.address_state) {
        invalid.push("state")
      }
      if (!address.address_city) {
        invalid.push("city")
      }
      if (!address.address_line1) {
        invalid.push("line1")
      }
      if (!address.ship_from) {
        invalid.push("shipFrom")
      }
      if (!address.receiving_way) {
        invalid.push("receivingWay")
      }
      return invalid
    } else if (changeTarget === "自動決済") {
      return []
    } else {
      console.error("unknown change target")
      return []
    }
  }, [address, changeTarget, email, name, phone])

  const requestChange = useCallback(
    async (body: Object) => {
      try {
        if (changeTarget === "お届け先住所") {
          await updateAddress(address.id, body)
        } else if (changeTarget === "お届け先住所登録") {
          await createAddress(body)
        } else {
          await updateMe(body)
        }
        setIsCompletedAlertOpen(true)
      } catch (e) {
        let message = `エラーが発生しました。入力をご確認ください。`
        if (e instanceof Response) {
          const body = await e.json()
          if (Array.isArray(body.messages)) {
            message = body.messages.join("\n")
          }
        }
        console.error(e)
        setRequestError(message)
      }
    },
    [address.id, changeTarget]
  )

  const handleOnClickConfirmButton = useCallback(() => {
    const invalid = invalidInputs() as InputType[]
    console.log(invalid)
    if (invalid.length === 0) {
      const body = createRequestBody()!
      requestChange(body)
    } else {
      setInvalidTypes(invalid)
    }
  }, [createRequestBody, invalidInputs, requestChange])

  function inputComponents() {
    if (changeTarget === "お名前") {
      return createInput("name", changeTarget, name)
    } else if (changeTarget === "お電話番号") {
      return createInput("phone", `${changeTarget} (ハイフン無し半角)`, phone)
    } else if (changeTarget === "メールアドレス") {
      return createInput("email", changeTarget, email)
    } else if (changeTarget === "自動決済") {
      return [
        createCheckBox(
          "use_auto_payment",
          useAutoPayment,
          "購入手続きを省く",
          () => setUseAutoPayment(!useAutoPayment),
          "チェックがついている場合\n処方箋発行と同時に決済完了となります。"
        ),
        createSmallSelectBox(
          "autoPaymentTimes",
          [
            { text: "一括", value: "" },
            { text: "3", value: "3" },
            { text: "5", value: "5" },
            { text: "6", value: "6" },
            { text: "10", value: "10" },
            { text: "12", value: "12" },
            { text: "15", value: "15" },
            { text: "18", value: "18" },
            { text: "20", value: "20" },
            { text: "24", value: "24" },
          ],
          autoPaymentTimes,
          false,
          "お支払い回数"
        ),
      ]
    } else if (
      changeTarget === "お届け先住所" ||
      changeTarget === "お届け先住所登録"
    ) {
      return [
        createInput(
          "zip",
          "郵便番号 (例：100-0005)",
          address.address_zip,
          (e) => {
            handleOnChangeInput(e.target.value, "zip")
            ZipCode.search(e.target.value).then((addr) => {
              setAddress({
                ...address,
                address_zip: addr.zip,
                address_state: addr.region,
                address_city: addr.locality + addr.street,
                address_line1: "",
                address_line2: "",
              })
            })
          }
        ),
        createInput("state", "都道府県 (例：東京都)", address.address_state),
        createInput(
          "city",
          "市区町村 町名 (例：千代田区丸の内)",
          address.address_city
        ),
        createInput("line1", "番地 (例：1-1)", address.address_line1),
        createInput(
          "line2",
          "マンション・部屋番号 (例：スマクリマンション 101)",
          address.address_line2
        ),
        createSelectBox(
          "receivingWay",
          [
            { text: "ご自宅", value: "at_home" },
            { text: "ヤマト運輸営業所", value: "delivery_center_pickup" },
          ],
          address.receiving_way,
          true,
          "お受け取り方法"
        ),
        createSelectBox(
          "shipFrom",
          [
            {
              text: "AGAスマクリ（医療法人社団ウェルパートナー）",
              value: "aga_smart_clinic",
            },
            { text: "田代友里子", value: "dr_tashiro" },
            { text: "医療法人社団ウェルパートナー", value: "well_partner" },
          ],
          address.ship_from,
          true,
          "発送元名"
        ),
      ]
    } else if (changeTarget === "支払い方法") {
      return createSelectBox(
        "paymentMethod",
        [
          { text: "クレジットカード", value: "credit_card" },
          { text: "デビットカード", value: "debit_card" },
          { text: "プリペイドカード", value: "prepaid_card" },
          { text: "銀行振り込み", value: "bank_transfer" },
        ],
        paymentMethod,
        true
      )
    }
  }

  function createInput(
    inputType: InputType,
    title: string,
    value: string | undefined,
    onChange?: Function
  ) {
    return (
      <Box key={inputType}>
        <Text fontSize="16px">{title}</Text>
        <Box height="4px" />
        <Input
          width="100%"
          borderRadius="0"
          borderColor={
            invalidTypes.includes(inputType)
              ? `${TenetColors.red.warning}`
              : TenetColors.gray._300
          }
          bg={TenetColors.white._100}
          onChange={(e) => {
            if (onChange) {
              onChange(e)
            } else {
              handleOnChangeInput(e.target.value, inputType)
            }
          }}
          value={value}
        />
        <Box height="16px" />
      </Box>
    )
  }

  function createCheckBox(
    inputType: InputType,
    value: boolean,
    title: string,
    onChange: Function,
    description?: string
  ) {
    return (
      <Box key={inputType}>
        <Box height="4px" />
        <Box display="flex" justifyContent="center">
          <Text style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
            {description}
          </Text>
        </Box>
        <Box display="flex" justifyContent="center" mt="32px">
          <label className="d-flex align-items-center">
            <input
              type="checkbox"
              onChange={(e) => onChange()}
              checked={value}
              style={{ height: "32px", width: "32px" }}
            ></input>
            <Text className="ml-2">{title}</Text>
          </label>
        </Box>
      </Box>
    )
  }

  function createSelectBox(
    inputType: InputType,
    options: Array<{ text: string; value: string }>,
    value?: string,
    allowBlank?: boolean,
    title?: string
  ) {
    return (
      <Box key={inputType}>
        <Text fontSize="16px">{title}</Text>
        <Select
          bg="white"
          borderColor={
            invalidTypes.includes(inputType)
              ? `${TenetColors.red.warning}`
              : TenetColors.gray._300
          }
          value={value}
          onChange={(e) => handleOnChangeInput(e.target.value, inputType)}
        >
          {allowBlank && <option value={undefined}></option>}
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            )
          })}
        </Select>
        <Box height="16px" />
      </Box>
    )
  }

  function createSmallSelectBox(
    inputType: InputType,
    options: Array<{ text: string; value: string }>,
    value?: string | null,
    allowBlank?: boolean,
    title?: string
  ) {
    return (
      <Flex
        key={inputType}
        justifyContent="center"
        alignItems="center"
        width="100%"
        mt="20px"
      >
        {title && (
          <Text fontSize="16px" className="mr-2">
            {title}
          </Text>
        )}
        <Select
          bg="white"
          borderColor={
            invalidTypes.includes(inputType)
              ? `${TenetColors.red.warning}`
              : TenetColors.gray._300
          }
          value={value ?? ""}
          onChange={(e) => handleOnChangeInput(e.target.value, inputType)}
          width="100px"
        >
          {allowBlank && <option value={undefined}></option>}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </Flex>
    )
  }

  const buttonText: string =
    changeTarget === "お届け先住所登録" ? "登録" : "変更"

  function handleOnChangeInput(text: string, inputType: InputType) {
    setInvalidTypes([])
    setRequestError(null)
    if (inputType === "name") {
      setName(text)
    } else if (inputType === "phone") {
      setPhone(text)
    } else if (inputType === "email") {
      setEmail(text)
    } else if (inputType === "zip" && address) {
      setAddress({
        ...address,
        address_zip: text,
      })
    } else if (inputType === "state" && address) {
      setAddress({
        ...address,
        address_state: text,
      })
    } else if (inputType === "city" && address) {
      setAddress({
        ...address,
        address_city: text,
      })
    } else if (inputType === "line1" && address) {
      setAddress({
        ...address,
        address_line1: text,
      })
    } else if (inputType === "line2" && address) {
      setAddress({
        ...address,
        address_line2: text,
      })
    } else if (inputType === "receivingWay" && address) {
      setAddress({
        ...address,
        receiving_way: text,
      })
    } else if (inputType === "shipFrom" && address) {
      setAddress({
        ...address,
        ship_from: text,
      })
    } else if (inputType === "paymentMethod") {
      setPaymentMethod(text)
    } else if (inputType === "autoPaymentTimes") {
      setAutoPaymentTimes(text)
    }
  }

  function completedAlertDialog() {
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={undefined}
        onClose={handleOnCloseAlert}
        isOpen={true}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent m="12px">
          <AlertDialogHeader>{alertDialogHeader()}</AlertDialogHeader>
          <AlertDialogBody>{alertDialogBody()}</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleOnCloseAlert}>OK</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  function alertDialogHeader(): string {
    return changeTarget === "メールアドレス"
      ? "ご注意：まだメールアドレスの変更は完了しておりません"
      : "変更が完了しました"
  }

  function alertDialogBody(): string {
    return changeTarget === "メールアドレス"
      ? "送信したメールから変更手続きを行ってください"
      : ""
  }

  const handleOnCloseAlert = useCallback(() => {
    props.history.goBack()
    setIsCompletedAlertOpen(false)
  }, [props.history])

  return (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title={`${changeTarget || ""}の変更`} />
      <Box>
        {inputComponents()}
        {requestError ? (
          <Text
            textAlign="center"
            textColor={TenetColors.red.warning}
            whiteSpace="pre-wrap"
          >
            {requestError}
          </Text>
        ) : null}
        {invalidTypes.length !== 0 ? (
          <Text textAlign="center" textColor={TenetColors.red.warning}>
            エラーがあります。入力内容をご確認ください。
          </Text>
        ) : null}
        <Box height="40px" />
        <Box m="0 27%">
          <ArrowButton
            width="100%"
            height="64px"
            text={buttonText}
            iconColor="orange"
            onClick={handleOnClickConfirmButton}
          />
        </Box>
        {changeTarget === "自動決済" && (
          <Box textAlign="center" className="my-3">
            <small>
              ※&ensp;次回の処方箋から支払い回数が変更されます。発行済みの処方箋の支払い回数変更につきましては、クレジットカード会社様へお問い合わせください。
            </small>
          </Box>
        )}
        <Box height="80px" />
      </Box>
      {isCompletedAlertOpen ? completedAlertDialog() : null}
    </Box>
  )
}

export default CustomerDetailChangePage
