import React from "react"
import { Text } from "@chakra-ui/layout"

type Props = {
  text: string | undefined
  maxWidth: string
}

const TitleText: React.FC<Props> = (props: Props) => {
  return (
    <Text fontSize="20px" maxWidth={props.maxWidth}>
      {props.text}
    </Text>
  )
}

export default TitleText
