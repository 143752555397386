import { Box, Text } from "@chakra-ui/react"
import React from "react"
import { TenetColors } from "../ui-helpers/ChakraTheme"

type Props = {
  type: string
  name: string
}

const AssignedUserNameSection: React.FC<Props> = (props: Props) => {
  return (
    <Box justifyContent="flex-start" m="0 8px 0 8px">
      <Text
        fontSize="14px"
        textColor={TenetColors.gray._400}
      >{`${props.type}：`}</Text>
      <Text fontSize="20px" textColor={TenetColors.gray._500}>
        {props.name}
      </Text>
      <Box height="24px" />
    </Box>
  )
}

export default AssignedUserNameSection
