import { CSSReset, Box, Image, Text, Divider } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import PageTitleSection from "../../ui-components/PageTitleSection"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import images from "../../ui-helpers/ImageSources"
import ArrowButton from "../../ui-components/ArrowButton"
import MeModel from "../../models/MeModel"
import { getMe } from "./CustomerDetailPageController"
import paths from "../../helpers/ROUTES_PATH"
import { useLocation } from "react-router-dom"
import queryString from "query-string"

type Props = {
  history: H.History
}

const CustomerDetailPage: React.FC<Props> = (props: Props) => {
  const [me, setMe] = useState<MeModel>()
  const [requestError, setRequestError] = useState<string | null>()
  const location = useLocation()

  async function requestMe() {
    try {
      const me = await getMe()
      setMe(me)
    } catch (e) {
      console.error(e)
      const message = `エラーが発生しました\n${e.name || ""} ${e.message}`
      setRequestError(message)
    }
  }

  function createComponent(params: {
    icon: string
    title: string
    text: string | null | undefined
    onClick?: () => void
    link?: string | null
    updatedAt?: string | undefined
  }) {
    const { icon, title, text, onClick, link, updatedAt } = params

    return (
      <>
        <Box
          display="flex"
          minH="118px"
          bg={TenetColors.white._100}
          justifyContent="space-between"
          alignItems="center"
          p="24px"
        >
          <Box>
            <Box display="flex" alignItems="center">
              <Image width="16px" src={icon} alt="会員情報" />
              <Text fontSize="16px" ml="8px">{`${title}：`}</Text>
            </Box>
            <Box height="6px" />
            <Text fontSize="16px" fontWeight="500">
              {text || " "}
            </Text>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <ArrowButton
              width="107px"
              height="60px"
              text="変更"
              iconColor="orange"
              icon={null}
              iconHeight={null}
              onClick={onClick}
              link={link}
              linkTarget={false}
            />
            <Text fontSize="12px" fontWeight="500" mt="10px">
              {updatedAt ? `最終更新日時 : ${updatedAt}` : " "}
            </Text>
          </Box>
        </Box>
        <Divider />
      </>
    )
  }

  const handleOnClickNameChange = useCallback(() => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "お名前", me: me },
    })
  }, [me, props.history])

  const handleOnClickPhoneNumberChange = useCallback(() => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "お電話番号", me: me },
    })
  }, [me, props.history])

  const handleOnClickEmailChange = useCallback(() => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "メールアドレス", me: me },
    })
  }, [me, props.history])

  const handleOnClickPaymentMethodChange = useCallback(() => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "支払い方法", me: me },
    })
  }, [me, props.history])

  const handleOnClickAddressesChange = useCallback(() => {
    props.history.push({
      pathname: paths.addressesEdit,
      state: { me },
    })
  }, [me, props.history])

  const handleOnClickCardChange = useCallback(() => {
    props.history.push({
      pathname: paths.creditCardEdit,
    })
  }, [props.history])

  const handleOnClickUseAutoPaymentChange = useCallback(() => {
    props.history.push({
      pathname: paths.customerDetailChange,
      state: { changeTarget: "自動決済", me: me },
    })
  }, [me, props.history])

  useEffect(() => {
    requestMe()
  }, [])

  useEffect(() => {
    if (queryString.parse(location.search).to === "address" && me) {
      props.history.replace(location.pathname)
      handleOnClickAddressesChange()
    }
  }, [
    me,
    location.search,
    props.history,
    location.pathname,
    handleOnClickAddressesChange,
  ])

  return requestError ? (
    <Text height="100vh">{requestError}</Text>
  ) : (
    <>
      <CSSReset />
      <BackButtonTopBar history={props.history} />
      <PageTitleSection title="会員情報" />
      <Box border={`2px solid ${TenetColors.gray.background}`}></Box>
      {createComponent({
        icon: images.personDark,
        title: "氏名",
        text: me?.fullName,
        onClick: handleOnClickNameChange,
      })}
      {createComponent({
        icon: images.houseDark,
        title: "お届け先住所",
        text: me?.address?.oneLine(),
        updatedAt: me?.address?.updatedAt,
        onClick: handleOnClickAddressesChange,
      })}
      {createComponent({
        icon: images.telephoneDark,
        title: "電話番号",
        text: me?.phoneNumber,
        onClick: handleOnClickPhoneNumberChange,
      })}
      {createComponent({
        icon: images.emailDark,
        title: "メールアドレス",
        text: me?.email,
        onClick: handleOnClickEmailChange,
      })}
      {createComponent({
        icon: images.lockDark,
        title: "パスワード",
        text: "＊＊＊＊＊＊＊＊＊＊＊",
        link: paths.customersEdit,
      })}
      {createComponent({
        icon: images.personDark,
        title: "お支払い方法の変更",
        text: me?.paymentMethodValue,
        onClick: handleOnClickPaymentMethodChange,
      })}
      {createComponent({
        icon: images.personDark,
        title: "クレジットカード",
        text: "",
        onClick: handleOnClickCardChange,
      })}
      {createComponent({
        icon: images.lockDark,
        title: "自動決済",
        text: "※クレジットカード払いの方のみが利用可能です",
        onClick: handleOnClickUseAutoPaymentChange,
      })}
      <Box height="40px" />
      <ArrowButton
        width="100%"
        height="72px"
        text="マイページからログアウトする"
        iconColor="orange"
        icon="door"
        iconHeight="30px"
        link={paths.customersSignOut}
        linkTarget={false}
      />
      <Box height="80px" />
    </>
  )
}

export default CustomerDetailPage
