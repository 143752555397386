import PrescriptionsListEntity from "../entities/PrescriptionsListEntity"
import ModelType from "./ModelType"
import PrescriptionModel from "./PrescriptionModel"

export default class PrescriptionsListModel implements ModelType {
  readonly prescriptions: PrescriptionModel[]

  constructor(entity: PrescriptionsListEntity) {
    this.prescriptions =
      entity.prescriptions?.map((p) => new PrescriptionModel(p)) || []
  }
}
