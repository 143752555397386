export const createRequestHeadersForCors: (options?: {
  withCsrfToken?: boolean
}) => Headers = (options) => {
  const withCsrfToken = options?.withCsrfToken ?? false

  const headers = new Headers()
  headers.set("Accept", "application/json")
  headers.set("Content-Type", "application/json")
  headers.set("X-Requested-With", "XMLHttpRequest")

  if (withCsrfToken) {
    const token = document!
      .querySelector("meta[name=csrf-token]")
      ?.getAttribute("content")

    if (token) {
      headers.set("X-CSRF-Token", token)
    }
  }

  return headers
}
