import MeModel from "../../models/MeModel"
import { getMe as request } from "../../api-helpers/APICalls"

export async function getMe(): Promise<MeModel> {
  try {
    return await request()
  } catch (e) {
    throw e
  }
}
