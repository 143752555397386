import React from "react"
import { Image, Box, Text, Flex } from "@chakra-ui/react"
import { TenetColors } from "../ui-helpers/ChakraTheme"
import images from "../ui-helpers/ImageSources"

type Props = {
  message: string | undefined
  boldPart: string | null | undefined
}

const MessageBubble: React.FC<Props> = (props: Props) => {
  const { message, boldPart } = props

  function splitWithBold(): string[] {
    if (!boldPart) {
      return message ? [message] : []
    } else if (message) {
      const split = message.split(boldPart)
      return [split[0], boldPart, split[1]] // 現状、太字箇所は1箇所のみと想定
    } else {
      return []
    }
  }
  const messages = splitWithBold()

  function textComponent(text: string, fontSize: string, fontWeight: string) {
    return (
      <Text
        fontSize={fontSize}
        lineHeight="24px"
        m="0"
        whiteSpace="pre-wrap"
        d="inline"
      >
        {text}
      </Text>
    )
  }

  return (
    <Box width="100%" position="relative">
      <Image src={images.messageBubble} alt="ふきだし" />
      <Flex
        position="absolute"
        top="0"
        right="0"
        width="93%"
        minH="84%"
        bgColor={TenetColors.white._100}
        p="8%"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          {textComponent(messages[0], "16px", "semi-bold")}
          {textComponent(messages[1], "24px", "bold")}
          {textComponent(messages[2], "16px", "semi-bold")}
        </Box>
      </Flex>
    </Box>
  )
}

export default MessageBubble
