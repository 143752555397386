import React from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ImageSources from "../ui-helpers/ImageSources"

// Import Swiper styles
import "swiper/swiper.scss"

SwiperCore.use([Autoplay])

const WaitingVideoCall: React.FC = () => {
  return (
    <Swiper autoplay={{ delay: 5000 }} loop={true} className="h-100">
      <SwiperSlide>
        <img
          className="w-100 h-100 object-fit-contain"
          src={ImageSources.waitingForMedicalExamination_1}
          alt="おまとめ便の案内"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="w-100 h-100 object-fit-contain"
          src={ImageSources.waitingForMedicalExamination_2}
          alt="治療効果"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="w-100 h-100 object-fit-contain"
          src={ImageSources.waitingForMedicalExamination_3}
          alt="症例"
        />
      </SwiperSlide>
    </Swiper>
  )
}

export default WaitingVideoCall
