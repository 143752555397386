import MedicalExaminationEntity from "../entities/MedicalExaminationEntity"
import DoctorModel from "./DoctorModel"
import ModelType from "./ModelType"
import MomentUtility from "../helpers/MomentUtility"

const enum MedicalExaminationStatusType {
  reserved = "reserved",
  draft = "draft",
  done = "done",
  canceled = "canceled",
}

export default class MedicalExaminationModel implements ModelType {
  readonly id: string
  readonly previousCounselingId: string | null
  readonly status: MedicalExaminationStatusType
  readonly examinationType:
    | "examination"
    | "sideEffect"
    | "counseling_by_doctor"
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string
  readonly canceledAt: string | null
  readonly doneAt: string | null
  readonly doctor: DoctorModel | null
  readonly hasCancelableSplitDispensingPrescription: boolean

  constructor(entity: MedicalExaminationEntity) {
    this.id = entity.id
    this.previousCounselingId = entity.previousCounselingId
    this.status = entity.status as MedicalExaminationStatusType
    this.examinationType = entity.examinationType
    this.videoCallURL = entity.videoCallURL
    this.createdAt = entity.createdAt
    this.reservationAt = entity.reservationAt
    this.canceledAt = entity.canceledAt
    this.doneAt = entity.doneAt
    this.doctor = entity.doctor ? new DoctorModel(entity.doctor) : null
    this.hasCancelableSplitDispensingPrescription =
      entity.hasCancelableSplitDispensingPrescription
  }

  reservationTimeWithoutYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }

  reservationTimeWithYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.year()}年${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }

  isStatusActive(): boolean {
    return this.status === MedicalExaminationStatusType.reserved
  }
}
