import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import CustomerHomePage from "./pages/CustomerHome/CustomerHomePage"
import { ChakraProvider, Container } from "@chakra-ui/react"
import theme, { TenetColors } from "./ui-helpers/ChakraTheme"
import paths from "./helpers/ROUTES_PATH"
import CustomerDetailPage from "./pages/CustomerDetail/CustomerDetailPage"
import DoctorDetailPage from "./pages/DoctorDetail/DoctorDetailPage"
import PrescriptionsPage from "./pages/Prescriptions/PrescriptionsPage"
import MedicalExaminationsPage from "./pages/MedicalExaminations/MedicalExaminationsPage"
import CustomerDetailChangePage from "./pages/CustomerDetailChange/CustomerDetailChangePage"
import CardEdit from "./pages/CardEdit/CardEditPage"
import CardCreate from "./pages/CardEdit/CardCreatePage"
import AddressesEdit from "./pages/AddressesEdit/AddressesEditPage"
import multipayment from "../public/utils/multipayment"
import PrescriptionShippingDetailPage from "./pages/PrescriptionShippingDetail/PrescriptionShippingDetailPage"
import PrescriptionShippingEditPage from "./pages/PrescriptionShippingDetail/PrescirpitonShippingEditPage"

type Props = {
  config: string
}

class CustomerApp extends React.Component<Props> {
  componentDidMount() {
    const config = JSON.parse(this.props.config)
    multipayment.init(config.gmo_pg_shop_id)
  }

  render() {
    return (
      <React.Fragment>
        <ChakraProvider theme={theme}>
          <Container
            maxW="container.sm"
            backgroundColor={TenetColors.gray.background}
          >
            <Router>
              <Switch>{routes}</Switch>
            </Router>
          </Container>
        </ChakraProvider>
      </React.Fragment>
    )
  }
}

const routes = [
  createRoute(paths.customerHome, CustomerHomePage),
  createRoute(paths.customerDetail, CustomerDetailPage),
  createRoute(paths.customerDetailChange, CustomerDetailChangePage),
  createRoute(paths.doctorDetail, DoctorDetailPage, false),
  createRoute(paths.prescriptions, PrescriptionsPage),
  createRoute(paths.medicalExaminations, MedicalExaminationsPage),
  createRoute(paths.creditCardEdit, CardEdit),
  createRoute(paths.creditCardCreate, CardCreate),
  createRoute(paths.addressesEdit, AddressesEdit),
  createRoute(
    paths.PrescriptionShippingDetail,
    PrescriptionShippingDetailPage,
    false
  ),
  createRoute(
    paths.PrescriptionShippingChange,
    PrescriptionShippingEditPage,
    false
  ),
] as const

function createRoute(path: string, component: React.FC, exact: boolean = true) {
  return exact ? (
    <Route exact path={path} component={component} key={path} />
  ) : (
    <Route path={path} component={component} key={path} />
  )
}

export default CustomerApp
