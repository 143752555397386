import moment, { Moment } from "moment"

export default class MomentUtility {
  private moment: Moment
  private format = "YYYY-MM-DDTHH:mm:ss.SSSSZ"

  constructor(time: string) {
    this.moment = moment(time)
  }

  year(): string {
    return `${this.moment.year()}`
  }

  month(): string {
    return `${this.moment.month() + 1}` // need to add 1
  }

  date(): string {
    return `${this.moment.date()}`
  }

  hour(): string {
    return `${this.moment.hour()}`
  }

  minute(): string {
    const minute = this.moment.minute() === 0 ? "00" : this.moment.minute()
    return `${minute}`
  }

  subtractDays(days: number): MomentUtility {
    const future = this.moment.subtract(days, "d").format(this.format)
    this.moment = moment(future)
    return this
  }
}
