import CounselingEntity from "../entities/CounselingEntity"
import CounselorModel from "./CounselorModel"
import ModelType from "./ModelType"
import MomentUtility from "../helpers/MomentUtility"

enum CounselingStatus {
  reserved = "reserved",
  draft = "draft",
  done = "done",
  canceled = "canceled",
}

export default class CounselingModel implements ModelType {
  readonly id: string
  readonly status: CounselingStatus
  readonly videoCallURL: string
  readonly createdAt: string
  readonly reservationAt: string | null
  readonly canceledAt: string | null
  readonly doneAt: string | null
  readonly counselor: CounselorModel | null

  constructor(entity: CounselingEntity) {
    this.id = entity.id
    this.status = entity.status as CounselingStatus
    this.videoCallURL = entity.videoCallURL
    this.createdAt = entity.createdAt
    this.reservationAt = entity.reservationAt
    this.canceledAt = entity.canceledAt
    this.doneAt = entity.doneAt
    this.counselor = entity.counselor
      ? new CounselorModel(entity.counselor)
      : null
  }

  reservationTimeWithoutYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }

  reservationTimeWithYear(): string | null {
    if (this.reservationAt) {
      const m = new MomentUtility(this.reservationAt)
      return `${m.year()}年${m.month()}月${m.date()}日 ${m.hour()}:${m.minute()}〜`
    } else {
      return null
    }
  }
}
