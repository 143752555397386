import { getAllPrescriptions } from "../../api-helpers/APICalls"
import PrescriptionModel from "../../models/PrescriptionModel"

export async function requestAllPrescriptions(): Promise<PrescriptionModel[]> {
  try {
    const list = await getAllPrescriptions()
    return list.prescriptions
  } catch (e) {
    throw e
  }
}
