import HistoryEntity from "../entities/HistoryEntity"
import ModelType from "./ModelType"

export default class HistoryModel implements ModelType {
  readonly zip: string | null
  readonly state: string | null
  readonly city: string | null
  readonly line1: string | null
  readonly line2: string | null
  readonly shipFrom: string | null
  readonly receivingWay: string | null
  readonly createdAt: string

  constructor(entity: HistoryEntity) {
    this.zip = entity.zip
    this.state = entity.state
    this.city = entity.city
    this.line1 = entity.line1
    this.line2 = entity.line2
    this.shipFrom = entity.shipFrom
    this.receivingWay = entity.receivingWay
    this.createdAt = entity.createdAt
  }
}
