import EntityType from "../models/EntityType"
import AddressEntity from "./AddressEntity"

export default class MeEntity implements EntityType {
  readonly address: AddressEntity | null
  readonly email: string | null
  readonly name: string | null
  readonly phone: string | null
  readonly paymentMethod: string | null
  readonly useAutoPayment: boolean
  readonly autoPaymentTimes: string | null

  constructor(json: any) {
    this.address = new AddressEntity(json)
    this.email = json["email"]
    this.name = json["name"]
    this.phone = json["phone"]
    this.paymentMethod = json["payment_method"]
    this.useAutoPayment = json["use_auto_payment"]
    this.autoPaymentTimes = json["auto_payment_times"]
  }
}
