import { Box, Image, Text } from "@chakra-ui/react"
import React from "react"
import { TenetColors } from "../../../ui-helpers/ChakraTheme"
import images from "../../../ui-helpers/ImageSources"

type Props = {
  medicines: string[]
  trackingNumber: string
}

const CustomerHomeDeliveryMedicinesSection: React.FC<Props> = (
  props: Props
) => {
  function medicineComponent(med: string) {
    return (
      <Box key={med} m="0 8px 8px 8px">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Image height="16px" src={images.medicineOrange} alt="お薬" />
          <Text ml="6px">{med}</Text>
        </Box>
      </Box>
    )
  }

  function trackingNumberComponent() {
    return (
      <Box justifyContent="flex-start" m="16px 24px 24px 8px">
        <Text fontSize="14px" textColor={TenetColors.gray._400}>
          伝票番号：
        </Text>
        <Box height="4px" />
        <Text fontSize="20px" textColor={TenetColors.gray._500}>
          {props.trackingNumber}
        </Text>
      </Box>
    )
  }

  return (
    <>
      {props.medicines.map((med) => medicineComponent(med))}
      {trackingNumberComponent()}
    </>
  )
}

export default CustomerHomeDeliveryMedicinesSection
