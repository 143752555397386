import { Box } from "@chakra-ui/react"
import React from "react"
import TopInfoModel, { TopInfoStatusType } from "../../../models/TopInfoModel"
import GradationDivider from "../../../ui-components/GradationDivider"
import CustomerHomeActionButtonSection from "./CustomerHomeActionButtonSection"
import AssignedUserNameSection from "../../../ui-components/AssignedUserNameSection"
import CustomerHomeDeliveryCautionSection from "./CustomerHomeDeliveryCautionSection"
import ReservationDatetimeSection from "../../../ui-components/ReservationDatetimeSection"
import CustomerHomeDeliveryMedicinesSection from "./CustomerHomeDeliveryMedicinesSection"
import CustomerHomeTalkButtonSection from "./CustomerHomeTalkButtonSection"
import CustomerHomeMedicinesPictureSection from "./CustomerHomeMedicinesPictureSection"
import DoctorInfoComponent from "../../../ui-components/DoctorInfoComponent"
import * as H from "history"

type Props = {
  topInfo: TopInfoModel | undefined
  history: H.History
}

const CustomerHomeActionView: React.FC<Props> = (props: Props) => {
  const { topInfo } = props
  if (!topInfo) {
    return <></>
  }
  const medicalExamination = topInfo.medicalExamination

  return (
    <Box>
      {topInfo.shouldHaveTopAndBottomMargins() ? <Box height="24px" /> : null}
      {topInfo.reservationDatetime() ? (
        <ReservationDatetimeSection datetime={topInfo.reservationDatetime()!} />
      ) : null}
      {topInfo.assignedUserName() ? (
        <AssignedUserNameSection
          name={topInfo.assignedUserName()!}
          type={topInfo.assignedUserType()!}
        />
      ) : null}
      {topInfo.deliveringMedicines() ? (
        <CustomerHomeDeliveryMedicinesSection
          medicines={topInfo.deliveringMedicines()!}
          trackingNumber={topInfo.trackingNumber()!}
        />
      ) : null}
      {topInfo.shouldShowMedicinesPicture() ? (
        <CustomerHomeMedicinesPictureSection
          picture={topInfo.medicinesPicture()!}
        />
      ) : null}
      {topInfo.actionButtonURL(topInfo.customerID) ? (
        <CustomerHomeActionButtonSection
          url={topInfo.actionButtonURL(topInfo.customerID)!}
          text={topInfo.actionButtonText()!}
          icon={topInfo.actionButtonIcon()!}
        />
      ) : null}
      {medicalExamination &&
      medicalExamination.hasCancelableSplitDispensingPrescription ? (
        <>
          <Box height="24px" />
          <CustomerHomeActionButtonSection
            onClick={() => {
              const searchParams = new URLSearchParams({
                action: "cancelPrescription",
                medicalExaminationId: medicalExamination.id,
              })
              window.location.href = `/withdrawal_interview/${
                topInfo.customerID
              }?${searchParams.toString()}`
            }}
            text="停止処理に進む"
            icon="caution"
          />
        </>
      ) : null}
      {topInfo.shouldShowDeliveryCaution() ? (
        <CustomerHomeDeliveryCautionSection
          caution={topInfo.deliveryCaution()!}
        />
      ) : null}
      {topInfo.talkButtonURL() ? (
        <CustomerHomeTalkButtonSection
          url={topInfo.talkButtonURL()!}
          text={topInfo.talkButtonText()!}
          caution={topInfo.talkButtonCaution()}
          cautionBoldPart={topInfo.talkButtonCautionBoldPart()}
        />
      ) : null}
      {topInfo.shouldShowDoctorInfo() ? (
        <DoctorInfoComponent
          doctor={
            topInfo.status === TopInfoStatusType.medicalExaminationAppointment
              ? medicalExamination?.doctor
              : topInfo.unableToPrescribeMedicalExamination?.doctor
          }
          history={props.history}
        />
      ) : null}
      {topInfo.shouldHaveTopAndBottomMargins() ? (
        <>
          <Box height="24px" />
          <GradationDivider />
        </>
      ) : null}
    </Box>
  )
}

export default CustomerHomeActionView
