import { Box, Divider, Text, Image } from "@chakra-ui/react"
import React from "react"
import PrescriptionModel from "../models/PrescriptionModel"
import PrescriptionDetailModel from "../models/PrescriptionDetailModel"
import { TenetColors } from "../ui-helpers/ChakraTheme"
import images from "../ui-helpers/ImageSources"
import * as H from "history"
import PrescriptionShippingItemComponent from "../pages/Prescriptions/PrescriptionShippingItemComponent"

type Props = {
  prescription: PrescriptionModel
  history: H.History
}

const PrescriptionDetailComponent: React.FC<Props> = (props: Props) => {
  const { prescription } = props

  const prescriptionMonths = prescription.mainPrescriptionMonth
  const prescriptionDivisionNumber =
    prescription.prescriptionShippings[0]?.divisionNumber
  const prescriptionTerm =
    prescriptionMonths && prescriptionDivisionNumber
      ? prescriptionMonths / prescriptionDivisionNumber
      : null

  function headerComponent() {
    return (
      <Box
        bg={
          prescription.hasShippedAlready()
            ? TenetColors.gray.transparent
            : TenetColors.orange.transparent
        }
        p="6px"
        display="flex"
        alignItems="center"
      >
        <Text fontSize="16px">
          {prescription.hasShippedAlready() ? "発送日時：" : ""}
          {prescription.deliveredDateJPN()}
        </Text>
        {prescription.isSplitDispensing && (
          <Box
            p="2px 8px"
            ml="auto"
            mr="10px"
            bg={
              prescription.hasShippedAlready()
                ? TenetColors.gray._400
                : TenetColors.orange._200
            }
          >
            <Text
              fontSize="14px"
              textColor={TenetColors.white._100}
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              ラクラク再購入 {prescription.splitDispensingTimes} /{" "}
              {prescription.totalSplitDispensingTimes}
            </Text>
          </Box>
        )}
        <Box
          p="2px 8px"
          bg={
            prescription.hasShippedAlready()
              ? TenetColors.gray._400
              : TenetColors.orange._200
          }
        >
          <Text
            fontSize="14px"
            textColor={TenetColors.white._100}
            textAlign="center"
            whiteSpace="pre-wrap"
          >
            {prescription.displayShippingStatus()}
          </Text>
        </Box>
      </Box>
    )
  }

  function prescriptionItemComponent(
    prescriptionDetail: PrescriptionDetailModel
  ) {
    return (
      <Box
        key={prescriptionDetail.medicineName + prescriptionDetail.spec}
        m="12px"
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Image
            height="16px"
            src={
              prescription.hasShippedAlready()
                ? images.medicineGray
                : images.medicineOrange
            }
            alt="お薬"
          />
          <Text fontSize="18x" ml="6px">
            {prescriptionDetail.displayText()}
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt="2px"
        >
          {(prescriptionDetail.month || prescriptionDetail.day) && (
            <>
              <Text fontSize="16px">
                {prescriptionDetail.month ? "処方月数" : "処方日数"}：
              </Text>
              <Text fontSize="16px" fontWeight="500">
                {prescriptionDetail.displayMedicineAmount()}
              </Text>
            </>
          )}
        </Box>
      </Box>
    )
  }

  function purchaseInfoComponent() {
    return (
      <Box m="12px">
        {purchaseInfoLine(
          prescription.hasShippedAlready()
            ? images.walletGray
            : images.walletOrange,
          "金額：",
          `${prescription.price}円`,
          "財布"
        )}
        {purchaseInfoLine(
          prescription.hasShippedAlready()
            ? images.clockGray
            : images.clockOrange,
          "処方日時：",
          `${prescription.displayPrescribedDate()}`,
          "時計"
        )}
        {purchaseInfoLine(
          prescription.hasShippedAlready()
            ? images.clockGray
            : images.clockOrange,
          "購入日時：",
          `${prescription.displayPurchasedDate()}`,
          "時計"
        )}
      </Box>
    )
  }

  function purchaseInfoLine(
    icon: string,
    title: string,
    detail: string,
    alt: string
  ) {
    return (
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb="4px"
      >
        <Image height="16px" src={icon} alt={alt} />
        <Text fontSize="16px" ml="6px">
          {title}
        </Text>
        <Text fontSize="16px" fontWeight="500">
          {detail}
        </Text>
      </Box>
    )
  }

  return (
    <>
      {prescription.prescriptionDetails.length !== 0 && (
        <Box
          mb="16px"
          border={`2px solid ${
            prescription.hasShippedAlready()
              ? TenetColors.gray._300
              : TenetColors.orange._200
          }`}
          bg={TenetColors.white._100}
        >
          {headerComponent()}
          {prescription.prescriptionDetails.map((prescriptionDetail) =>
            prescriptionItemComponent(prescriptionDetail)
          )}
          <Divider />
          {purchaseInfoComponent()}
          <Divider />
          {prescription.prescriptionShippings
            .sort(
              (a, b) =>
                Date.parse(a.deliveryStartAt) - Date.parse(b.deliveryStartAt)
            )
            .map((item, i) => {
              return (
                <PrescriptionShippingItemComponent
                  key={item.id}
                  prescriptionShipping={item}
                  prescription={prescription}
                  index={prescription.splitDispensingTimes || i + 1}
                  history={props.history}
                  prescriptionTerm={prescriptionTerm}
                  kuronekoTrackigURL={prescription.kuronekoTrackigURL()}
                />
              )
            })}
        </Box>
      )}
    </>
  )
}

export default PrescriptionDetailComponent
