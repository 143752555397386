import React, { useEffect, useState, useCallback } from "react"
import { Box, Center, CSSReset, Text } from "@chakra-ui/react"
import CustomerHomeTopBar from "../../ui-components/CustomerHome/CustomerHomeTopBar"
import { requestCustomerHomeInfo } from "./CustomerHomePageController"
import CustomerHomeInfo from "./CustomerHomeInfo"
import CustomerHomeDoctorMessageView from "../../ui-components/CustomerHome/CustomerHomeDoctorMessageView"
import CustomerHomeActionView from "./ActionView/CustomerHomeActionView"
import CustomerHomeListSwitchButtonsSection, {
  CustomerHomeListType,
} from "./CustomerHomeListSwitchButtonsSection"
import { RouteComponentProps } from "react-router-dom"
import PrescriptionsListSection from "../../ui-components/PrescriptionsListSection"
import MedicalExaminationsListSection from "../../ui-components/MedicalExaminationsListSection"
import CustomerHomeFooterSection from "./CustomerHomeFooterSection"

interface Props extends RouteComponentProps<{}> {}

const CustomerHomePage: React.FC<Props> = (props: Props) => {
  const [displayInfo, setDisplayinfo] = useState<CustomerHomeInfo>()
  const [requestError, setRequestError] = useState<string | null>()
  const [
    currentChosenType,
    setCurrentChosenType,
  ] = useState<CustomerHomeListType>(CustomerHomeListType.prescriptionsList)

  useEffect(() => {
    prepareDisplayInfo()
  }, [])

  async function prepareDisplayInfo() {
    try {
      setDisplayinfo(await requestCustomerHomeInfo())
      setRequestError(null)
    } catch (e) {
      console.error(e)
      const message = `エラーが発生しました\n${e.name || ""} ${e.message}`
      setRequestError(message)
    }
  }

  const onClickPrescriptionsListButton = useCallback(() => {
    setCurrentChosenType(CustomerHomeListType.prescriptionsList)
  }, [])

  const onClickMedicalExaminationListButton = useCallback(() => {
    setCurrentChosenType(CustomerHomeListType.medicalExaminationsList)
  }, [])

  function emptyView() {
    return (
      <Center height="200px">
        <Text>履歴がありません</Text>
      </Center>
    )
  }

  function isPrescriptionsEmpty(): boolean {
    return (
      currentChosenType === CustomerHomeListType.prescriptionsList &&
      displayInfo?.prescriptions.length === 0
    )
  }

  function isMedicalExaminationsEmpty(): boolean {
    return (
      currentChosenType === CustomerHomeListType.medicalExaminationsList &&
      displayInfo?.medicalExaminations.medicalExaminations.length === 0 &&
      !displayInfo?.medicalExaminations.counseling
    )
  }

  return requestError ? (
    <Text height="100vh">{requestError}</Text>
  ) : (
    <>
      <CSSReset />
      <CustomerHomeTopBar
        customerName={displayInfo?.nameTitleText()}
        history={props.history}
      />
      <CustomerHomeDoctorMessageView
        topInfo={displayInfo?.topInfo}
        me={displayInfo?.me}
      />

      <CustomerHomeActionView
        topInfo={displayInfo?.topInfo}
        history={props.history}
      />
      <CustomerHomeListSwitchButtonsSection
        onClickPrescriptionsListButton={onClickPrescriptionsListButton}
        onClickMedicalExaminationListButton={
          onClickMedicalExaminationListButton
        }
        currentChosenType={currentChosenType}
      />
      {currentChosenType === CustomerHomeListType.prescriptionsList ? (
        <PrescriptionsListSection
          prescriptions={displayInfo?.prescriptions || []}
          shouldShowMoreButton={
            displayInfo?.shouldShowPrescriptionsListMoreButton() || false
          }
          history={props.history}
        />
      ) : (
        <MedicalExaminationsListSection
          list={displayInfo?.medicalExaminations}
          shouldShowMoreButton={
            displayInfo?.shouldShowMedicalExaminationListMoreButton() || false
          }
          history={props.history}
          customerId={displayInfo?.topInfo.customerID}
        />
      )}
      {isPrescriptionsEmpty() ? emptyView() : null}
      {isMedicalExaminationsEmpty() ? emptyView() : null}
      <Box height="80px" />
      <CustomerHomeFooterSection />
    </>
  )
}

export default CustomerHomePage
