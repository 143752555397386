const ImageSources = {
  customer: require("assets/customer_icon.png"),
  doctorIllustration: require("assets/doctor_illustration.png"),
  messageBubble: require("assets/message_bubble.png"),
  calendar: require("assets/calendar_icon.png"),
  orangeArrow: require("assets/arrow_orange_icon.png"),
  user: require("assets/user_icon.png"),
  userCircle: require("assets/user_circle_icon.png"),
  cart: require("assets/cart_icon.png"),
  nextGrayArrow: require("assets/next_gray_arrow_icon.png"),
  door: require("assets/door_icon.png"),
  caution: require("assets/caution_icon.png"),
  magnifier: require("assets/magnifier_icon.png"),
  medicinePic: require("assets/medicines_picture.png"),
  medicineOrange: require("assets/medicine_orange_icon.png"),
  medicineGray: require("assets/medicine_gray_icon.png"),
  walletOrange: require("assets/wallet_orange_icon.png"),
  walletGray: require("assets/wallet_gray_icon.png"),
  clockOrange: require("assets/clock_orange_icon.png"),
  clockGray: require("assets/clock_gray_icon.png"),
  clipboardOrange: require("assets/clipboard_orange_icon.png"),
  clipboardGray: require("assets/clipboard_gray_icon.png"),
  stethoscopeOrange: require("assets/stethoscope_orange_icon.png"),
  stethoscopeGray: require("assets/stethoscope_gray_icon.png"),
  backHomeArrow: require("assets/back_home_arrow_icon.png"),
  personDark: require("assets/person_dark_icon.png"),
  houseDark: require("assets/house_dark_icon.png"),
  telephoneDark: require("assets/telephone_dark_icon.png"),
  emailDark: require("assets/email_dark_icon.png"),
  lockDark: require("assets/lock_dark_icon.png"),
  footerLogo: require("assets/footer_logo_image.png"),
  virtualBackGround: require("assets/virtual_background.png"),
  waitingForMedicalExamination_1: require("assets/waiting_medical_examination_1.png"),
  waitingForMedicalExamination_2: require("assets/waiting_medical_examination_2.png"),
  waitingForMedicalExamination_3: require("assets/waiting_medical_examination_3.png"),
} as const

export default ImageSources
