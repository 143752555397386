import EntityType from "../models/EntityType"
import AddressEntity from "./AddressEntity"
import HistoryEntity from "./HistoryEntity"

export default class AddressListEntity implements EntityType {
  readonly addresses: AddressEntity[]
  readonly updateHistories: HistoryEntity[]

  constructor(json: any) {
    this.addresses = json["addresses"].map((a) => new AddressEntity(a))
    this.updateHistories = json["update_histories"].map(
      (u) => new HistoryEntity(u)
    )
  }
}
