import { Box, Text } from "@chakra-ui/react"
import React from "react"
import { TenetColors } from "../ui-helpers/ChakraTheme"

type Props = {
  datetime: string
}

const ReservationDatetimeSection: React.FC<Props> = (props: Props) => {
  return (
    <Box justifyContent="flex-start" m="0 8px 0 8px">
      <Text fontSize="14px" textColor={TenetColors.gray._400}>
        予約日時：
      </Text>
      <Text fontSize="28px" textColor={TenetColors.gray._500}>
        {props.datetime}
      </Text>
      <Box height="24px" />
    </Box>
  )
}

export default ReservationDatetimeSection
