import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import * as H from "history"
import { Box } from "@chakra-ui/react"
import ArrowButton from "../../ui-components/ArrowButton"
import PrescriptionShippingModel from "../../models/PrescriptionShippingModel"
import paths from "./../../helpers/ROUTES_PATH"
import PrescriptionModel from "../../models/PrescriptionModel"

type Props = {
  history: H.History
}

const PrescriptionShippingDetailPage: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const [shipping, setShipping] = useState<PrescriptionShippingModel>()
  const [prescription, setPrescription] = useState<PrescriptionModel>()
  const [index, setIndex] = useState<number | null>()
  const [kuronekoTrackingURL, setKuronekoTrackingURL] = useState<
    string | null
  >()

  useEffect(() => {
    const state = location.state as Object
    setShipping(state["prescriptionShipping"])
    setPrescription(state["prescription"])
    setKuronekoTrackingURL(state["kuronekoTrackingURL"])
    setIndex(state["index"])
  }, [])

  const handleOnClickEditButton = useCallback(() => {
    const path = `${paths.PrescriptionShippingChange}${shipping?.id}`
    props.history.push({
      pathname: path,
      state: {
        prescriptionShipping: shipping,
        prescription: prescription,
      },
    })
  }, [props.history, shipping, prescription])

  return (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <Box>
        <Box height="40px" />
        <Box width="246px" m="0 auto">
          <ArrowButton
            iconColor="orange"
            iconHeight="28px"
            width="100%"
            height="64px"
            text="配送状況を確認する"
            link={kuronekoTrackingURL}
            linkTarget={true}
          />
        </Box>
      </Box>
      {index !== 0 && (
        <Box mt="24px">
          <Box width="246px" m="0 auto">
            <ArrowButton
              width="100%"
              height="64px"
              text={`お届け先を\n確認/変更する`}
              iconColor="orange"
              onClick={handleOnClickEditButton}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PrescriptionShippingDetailPage
