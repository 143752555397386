import EntityType from "../models/EntityType"
import CounselingEntity from "./CounselingEntity"
import MedicalExaminationEntity from "./MedicalExaminationEntity"

export default class MedicalExaminationsListEntity implements EntityType {
  readonly medicalExaminations: Array<MedicalExaminationEntity> | null
  readonly counseling: CounselingEntity | null

  constructor(json: any) {
    this.medicalExaminations = json["medical_examinations"].map(
      (m: any) => new MedicalExaminationEntity(m)
    )
    this.counseling = json["counseling"]
      ? new CounselingEntity(json["counseling"])
      : null
  }
}
