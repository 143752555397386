export default function fetchWithErrorHandling(
  url: string,
  method: string,
  body: Object | null
): any {
  return new Promise((resolve, reject) => {
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement)?.content
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    headers.set("X-CSRF-Token", csrfToken)

    const json = body && method !== "GET" ? JSON.stringify(body) : null

    fetch(url, { method: method, headers: headers, body: json })
      .catch((e) => {
        reject(e)
      })
      .then((res: Response) => {
        if (!res.ok) {
          reject(res)
        } else if (res.status === 204) {
          return res
        } else {
          return res.json()
        }
      })
      .catch((e: SyntaxError) => {
        reject(new SyntaxError(e.message))
      })
      .catch((e) => {
        reject(e)
      })
      .then((data: any) => {
        resolve(data)
      })
  })
}
