import { extendTheme } from "@chakra-ui/react"

export const TenetColors = {
  gray: {
    transparent: "rgba(181, 181, 181, 0.35)",
    background: "#EEEEEE",
    _100: "#E5E5E5",
    _200: "#E1E1E1",
    _300: "#999999",
    _400: "#858585",
    _500: "#423635",
  },
  orange: {
    transparent: "rgba(225, 88, 58, 0.1)",
    _100: "#E57A62",
    _200: "#E25839",
  },
  white: {
    _100: "#FAFAFA",
  },
  red: {
    warning: "#FF0000",
  },
}

export default extendTheme({
  components: {
    Text: {
      baseStyle: {
        fontWeight: "700",
        fontFamily:
          "YuGothic, 游ゴシック, YuGothic, 游ゴシック体, ヒラギノ角ゴ Pro W3, メイリオ, sans-serif",
        color: TenetColors.gray._500,
        wordBreak: "break-word",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "700",
        fontFamily:
          "YuGothic, 游ゴシック, YuGothic, 游ゴシック体, ヒラギノ角ゴ Pro W3, メイリオ, sans-serif",
        color: TenetColors.gray._500,
        borderRadius: "16px",
        borderColor: "transparent",
        wordBreak: "break-word",
      },
    },
  },
})
