import { Box, Text, Avatar, Spacer } from "@chakra-ui/react"
import * as H from "history"
import React, { useCallback } from "react"
import { TenetColors } from "../ui-helpers/ChakraTheme"
import paths from "../helpers/ROUTES_PATH"
import ArrowButton from "./ArrowButton"
import DoctorModel from "../models/DoctorModel"

type Props = {
  doctor: DoctorModel | undefined | null
  history: H.History
}

const DoctorInfoComponent: React.FC<Props> = (props: Props) => {
  const handleOnClickDoctorDetail = useCallback(() => {
    const path = paths.doctorDetail + props.doctor?.id
    props.history.push({
      pathname: path,
      state: { doctor: props.doctor },
    })
  }, [props.history, props.doctor])

  return (
    <Box justifyContent="flex-start" m="16px 16px 0 16px">
      <Text fontSize="14px" textColor={TenetColors.gray._400}>
        担当医師：
      </Text>
      <Box display="flex" alignItems="center" mt="12px">
        <Avatar
          height="80px"
          width="80px"
          src={props.doctor?.profileImageURL || ""}
        />
        <Text fontSize="20px" textColor={TenetColors.gray._500} ml="8px">
          {props.doctor?.name || ""}
        </Text>
        <Spacer flex="2" />
        <ArrowButton
          icon={null}
          iconColor="gray"
          iconHeight={null}
          width="107px"
          height="62px"
          text="詳細"
          onClick={handleOnClickDoctorDetail}
          linkTarget={false}
        />
      </Box>
    </Box>
  )
}

export default DoctorInfoComponent
