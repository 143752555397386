import { Spacer, Box } from "@chakra-ui/react"
import React from "react"
import IconButton from "../../ui-components/IconButton"

export const enum CustomerHomeListType {
  prescriptionsList,
  medicalExaminationsList,
}

type Props = {
  onClickPrescriptionsListButton: () => void
  onClickMedicalExaminationListButton: () => void
  currentChosenType: CustomerHomeListType
}

const CustomerHomeListSwitchButtonsSection: React.FC<Props> = (
  props: Props
) => {
  const sizes = {
    width: "43%",
    height: "120px",
    fontSize: "18px",
    iconHeight: "39px",
  }

  return (
    <>
      <Box height="40px" />
      <Box display="flex" justifyContent="center">
        <IconButton
          title="処方箋履歴"
          icon={
            props.currentChosenType === CustomerHomeListType.prescriptionsList
              ? "clipboardGray"
              : "clipboardOrange"
          }
          isPressed={
            props.currentChosenType === CustomerHomeListType.prescriptionsList
          }
          onClick={props.onClickPrescriptionsListButton}
          width={sizes.width}
          height={sizes.height}
          fontSize={sizes.fontSize}
          iconHeight={sizes.iconHeight}
        />
        <Spacer flex="0.4" />
        <IconButton
          title="診療履歴"
          icon={
            props.currentChosenType ===
            CustomerHomeListType.medicalExaminationsList
              ? "stethoscopeGray"
              : "stethoscopeOrange"
          }
          isPressed={
            props.currentChosenType ===
            CustomerHomeListType.medicalExaminationsList
          }
          onClick={props.onClickMedicalExaminationListButton}
          width={sizes.width}
          height={sizes.height}
          fontSize={sizes.fontSize}
          iconHeight={sizes.iconHeight}
        />
      </Box>
    </>
  )
}

export default CustomerHomeListSwitchButtonsSection
