import { Flex } from "@chakra-ui/layout"
import React, { useCallback } from "react"
import IconButton from "./IconButton"
import * as H from "history"

type Props = {
  history: H.History
}

const BackButtonTopBar: React.FC<Props> = (props: Props) => {
  const handleOnClickBack = useCallback(() => {
    props.history.goBack()
  }, [props.history])

  return (
    <Flex height="112px" align="center" justify="flex-start">
      <IconButton
        isPressed={false}
        width={"80px"}
        height={"80px"}
        icon={"backHomeArrow"}
        title={"戻る"}
        fontSize={"14px"}
        iconHeight={"28px"}
        onClick={handleOnClickBack}
      />
    </Flex>
  )
}

export default BackButtonTopBar
