import React, { useEffect, useState } from "react"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import { Input, Box, Text } from "@chakra-ui/react"
import * as H from "history"
import BackButtonTopBar from "../../ui-components/BackButtonTopBar"
import PageTitleSection from "../../ui-components/PageTitleSection"
import ArrowButton from "../../ui-components/ArrowButton"
import multipayment from "../../../public/utils/multipayment"
import paths from "../../helpers/ROUTES_PATH"
import { useLocation } from "react-router-dom"
import { createRequestHeadersForCors } from "../../../public/utils/request_headers"

type Props = {
  history: H.History
}

type ValidationError = {
  number?: string
  name?: string
  expMonth?: string
  expUear?: string
  cvc?: string
}

type LocationState = {
  sequence: string
}

const CustomerCardCreatePage: React.FC<Props> = (props: Props) => {
  const [validateErrors, setValidateErrors] = useState<ValidationError>({})
  const [number, setNumber] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [expMonth, setExpMonth] = useState<string>("")
  const [expYear, setExpYear] = useState<string>("")
  const [cvc, setCvc] = useState<string>("")
  const [sequence, setSequence] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState(false)

  const today = new Date()

  const validate = () => {
    let message: ValidationError = {}

    if (number.length === 0) {
      message["number"] = "クレジットカード番号を入力してください"
    }

    if (name.length === 0) {
      message["name"] = "クレジットカードの名前を入力してください"
    } else if (!name.match(/^[A-Za-z ]+$/i)) {
      message["name"] =
        "クレジットカードの名前は英字とスペースのみにしてください"
    }

    if (expMonth.length === 0) {
      message["expMonth"] = "クレジットカードの有効期限 (月) を入力してください"
    }

    if (expYear.length === 0) {
      message["expYear"] = "クレジットカードの有効期限 (年) を入力してください"
    }

    if (cvc.length === 0) {
      message["cvc"] = "クレジットカードのCVCを入力してください"
    }
    return message
  }

  const getCreditCardToken = () => {
    return multipayment.getToken({
      holdername: name,
      cardno: number,
      expire: `20${expYear}${expMonth}`,
      securitycode: cvc,
    })
  }

  const location = useLocation<LocationState>()

  useEffect(() => {
    const state = location.state
    if (state && state["sequence"]) {
      setSequence(state["sequence"])
    }
  }, [location.state, setSequence])

  const addCard = async () => {
    setSubmitting(true)
    const errorMessage = validate()
    setValidateErrors(errorMessage)

    if (Object.keys(errorMessage).length > 0) {
      setSubmitting(false)
      return
    }

    let token: string
    try {
      token = await getCreditCardToken()
    } catch {
      window.alert("クレジットカード情報をお確かめください。")
      setSubmitting(false)
      return
    }

    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    const createCard = () => {
      return fetch("/api/cards", {
        method: "POST",
        body: JSON.stringify({
          token,
        }),
        headers,
      })
    }
    const updateCard = (sequence: string) => {
      return fetch(`/api/cards/${sequence}`, {
        method: "PATCH",
        body: JSON.stringify({
          token,
        }),
        headers,
      })
    }

    try {
      const res = await (sequence === null
        ? createCard()
        : updateCard(sequence))
      if (!res.ok) {
        throw new Error()
      }
    } catch {
      window.alert("クレジットカード情報をお確かめください。")
      setSubmitting(false)
      return
    }

    props.history.push({
      pathname: paths.creditCardEdit,
    })
  }

  const confirmDelete = () => {
    if (window.confirm("カード情報を削除しますか？")) {
      deleteCard()
    }
  }

  const deleteCard = async () => {
    const headers = createRequestHeadersForCors()

    await fetch(`/api/cards/${sequence}`, {
      method: "DELETE",
      headers,
    }).then((response) => {
      if (response.ok) {
        props.history.replace(paths.creditCardEdit, {
          message: "クレジットカード情報は正常に削除されました",
        })
      } else {
        window.alert(
          "クレジットカード情報の削除に失敗しました。お手数をおかけしますが時間をおいてもう一度お試しください。"
        )
      }
    })
  }

  return (
    <Box minH="100vh">
      <BackButtonTopBar history={props.history} />
      <PageTitleSection
        title={`クレジットカードの${sequence === null ? "追加" : "更新"}`}
      />
      <Box px="2">
        <Box>
          <Text fontSize="16px">カード番号&emsp;例）1234567890123456</Text>
          <Input
            name="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            maxLength={16}
            bg={TenetColors.white._100}
            borderColor={
              validateErrors["number"]
                ? `${TenetColors.red.warning}`
                : TenetColors.gray._300
            }
          />
          {validateErrors["number"] && (
            <p className="text-danger">{validateErrors["number"]}</p>
          )}
        </Box>

        <Box height="16px" />

        <Box>
          <Text fontSize="16px">カード氏名&emsp;例）TARO&ensp;YAMADA</Text>
          <Input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            bg={TenetColors.white._100}
            borderColor={
              validateErrors["name"]
                ? `${TenetColors.red.warning}`
                : TenetColors.gray._300
            }
          />
          {validateErrors["name"] && (
            <p className="text-danger">{validateErrors["name"]}</p>
          )}
          <small>
            ※&ensp;半角英字&emsp;カードに刻印されている表記の通りにご入力ください
          </small>
        </Box>

        <Box height="16px" />

        <Box>
          <Text fontSize="16px">有効期限（月/年）</Text>
          <Box display="flex">
            <div>
              <select
                name="expMonth"
                className={`form-control${
                  validateErrors["expMonth"] ? " is-invalid" : ""
                }`}
                onChange={(e) => setExpMonth(e.target.value)}
              >
                <option value={""}>--月--</option>
                {Array.from({ length: 12 }, (_, i) =>
                  `${i + 101}`.slice(-2)
                ).map((month) => (
                  <option value={month} key={month}>
                    {month}月
                  </option>
                ))}
              </select>
              {validateErrors["expMonth"] && (
                <p className="text-danger">{validateErrors["expMonth"]}</p>
              )}
            </div>
            <div className="ml-3">
              <select
                name="expYear"
                className={`form-control${
                  validateErrors["expYear"] ? " is-invalid" : ""
                }`}
                onChange={(e) => setExpYear(e.target.value)}
              >
                <option value={""}>--年--</option>
                {Array.from({ length: 20 }, (_, i) =>
                  `${i + today.getFullYear()}`.slice(-2)
                ).map((year) => (
                  <option value={year} key={year}>
                    {year}年
                  </option>
                ))}
              </select>
              {validateErrors["expYear"] && (
                <p className="text-danger">{validateErrors["expYear"]}</p>
              )}
            </div>
          </Box>
          <small>※&ensp;カードに刻印されている表記の通りにご選択ください</small>
        </Box>

        <Box height="16px" />

        <Box>
          <Text fontSize="16px">セキュリティコード</Text>
          <Input
            name="cvc"
            value={cvc}
            onChange={(e) => setCvc(e.target.value)}
            bg={TenetColors.white._100}
            borderColor={
              validateErrors["cvc"]
                ? `${TenetColors.red.warning}`
                : TenetColors.gray._300
            }
          />
          {validateErrors["cvc"] && (
            <p className="text-danger">{validateErrors["cvc"]}</p>
          )}
          <small>
            ※&ensp;セキュリティコードとは、クレジットカードの裏面または表面に記載された3桁もしくは4桁の番号のことです。
            <br />
            カードを所有していない第三者による「なりすまし決済」等の不正利用を防止するためのコードになります。
          </small>
        </Box>

        <Box height="16px" />

        <ArrowButton
          width="100%"
          height="60px"
          text={sequence === null ? "追加する" : "更新する"}
          iconColor="orange"
          icon={null}
          iconHeight={null}
          onClick={addCard}
          linkTarget={false}
          disabled={submitting}
        />

        <Box height="16px" />

        {sequence !== null && (
          <ArrowButton
            width="100%"
            height="60px"
            text="削除する"
            iconColor="orange"
            icon={null}
            iconHeight={null}
            onClick={confirmDelete}
            linkTarget={false}
          />
        )}
      </Box>
    </Box>
  )
}

export default CustomerCardCreatePage
