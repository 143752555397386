import { Box, Divider, Text } from "@chakra-ui/react"
import React from "react"
import CounselingModel from "../models/CounselingModel"
import { TenetColors } from "../ui-helpers/ChakraTheme"
import AssignedUserNameSection from "./AssignedUserNameSection"
import ReservationDatetimeSection from "./ReservationDatetimeSection"

type Props = {
  counseling: CounselingModel
}

const CounselingDoneDetailComponent: React.FC<Props> = (props: Props) => {
  const { counseling } = props

  function headerComponent() {
    return (
      <Box
        bg={TenetColors.gray.transparent}
        p="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="16px">初回カウンセリング</Text>
        <Box p="2px 8px" bg={TenetColors.gray._400} width="72px">
          <Text
            fontSize="14px"
            textColor={TenetColors.white._100}
            textAlign="center"
          >
            完了
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      mb="16px"
      border={`2px solid ${TenetColors.gray._300}`}
      bg={TenetColors.white._100}
    >
      {headerComponent()}
      <Box height="8px" />
      <ReservationDatetimeSection
        datetime={counseling.reservationTimeWithYear() || ""}
      />
      <Divider color={TenetColors.gray.background} />
      <Box height="8px" />
      <AssignedUserNameSection
        type="担当カウンセラー"
        name={counseling.counselor?.name || ""}
      />
    </Box>
  )
}

export default CounselingDoneDetailComponent
