import MedicalExaminationsListModel from "../../models/MedicalExaminationsListModel"
import MeModel from "../../models/MeModel"
import PrescriptionModel from "../../models/PrescriptionModel"
import PrescriptionsListModel from "../../models/PrescriptionsListModel"
import TopInfoModel from "../../models/TopInfoModel"

export default class CustomerHomeInfo {
  readonly topInfo: TopInfoModel
  readonly me: MeModel
  readonly medicalExaminations: MedicalExaminationsListModel
  readonly prescriptions: PrescriptionModel[]

  constructor(params: {
    me: MeModel
    topInfo: TopInfoModel
    medicalExaminations: MedicalExaminationsListModel
    prescriptions: PrescriptionsListModel
  }) {
    this.topInfo = params.topInfo
    this.me = params.me
    this.medicalExaminations = params.medicalExaminations
    this.prescriptions = params.prescriptions.prescriptions
  }

  nameTitleText(): string {
    const name = this.me.fullName
    return name ? `${name}様専用ページ` : ""
  }

  shouldShowPrescriptionsListMoreButton(): boolean {
    return this.prescriptions.length === 3
  }

  shouldShowMedicalExaminationListMoreButton(): boolean {
    return this.medicalExaminations.medicalExaminations.length === 3
  }
}
