import EntityType from "../models/EntityType"

export default class PrescriptionShippingEntity implements EntityType {
  readonly id: string
  readonly prescriptionID: string
  readonly status: string
  readonly code: string
  readonly deliveryStartAt: string
  readonly deliveredAt: string | null
  readonly divisionNumber: number | null
  readonly trackingNumber: string | null
  readonly addressCity: string | null
  readonly addressLine1: string | null
  readonly addressLine2: string | null
  readonly addressState: string | null
  readonly addressZip: string | null
  readonly shipFrom: string | null
  readonly receivingWay: string | null
  readonly createdAt: string
  readonly updatedAt: string

  constructor(json: any) {
    this.id = json["id"]
    this.prescriptionID = json["prescription_id"]
    this.status = json["status"]
    this.code = json["code"]
    this.deliveryStartAt = json["delivery_start_at"]
    this.deliveredAt = json["delivered_at"]
    this.divisionNumber = json["division_number"]
    this.trackingNumber = json["tracking_number"]
    this.addressCity = json["address_city"]
    this.addressLine1 = json["address_line1"]
    this.addressLine2 = json["address_line2"]
    this.addressState = json["address_state"]
    this.addressZip = json["address_zip"]
    this.shipFrom = json["ship_from"]
    this.receivingWay = json["receiving_way"]
    this.createdAt = json["created_at"]
    this.updatedAt = json["updated_at"]
  }
}
