import EntityType from "../models/EntityType"

export default class AddressEntity implements EntityType {
  readonly id: number
  readonly addressZip: string | null
  readonly addressState: string | null
  readonly addressCity: string | null
  readonly addressLine1: string | null
  readonly addressLine2: string | null
  readonly addressShipFrom: string | null
  readonly addressReceivingWay: string | null
  readonly default: boolean | null
  readonly updatedAt: string

  constructor(json: any) {
    this.id = json["id"]
    this.addressZip = json["address_zip"]
    this.addressState = json["address_state"]
    this.addressCity = json["address_city"]
    this.addressLine1 = json["address_line1"]
    this.addressLine2 = json["address_line2"]
    this.addressShipFrom = json["ship_from"]
    this.addressReceivingWay = json["receiving_way"]
    this.default = json["default"]
    this.updatedAt = json["updated_at"]
  }

  convertToJson() {
    return {
      address_zip: this.addressZip,
      address_state: this.addressState,
      address_city: this.addressCity,
      address_line1: this.addressLine1,
      address_line2: this.addressLine2,
    }
  }
}
