import React from "react"
import { Divider } from "@chakra-ui/react"

export default function GradationDivider() {
  return (
    <Divider
      height="1px"
      boxShadow="0px 0.5px 0px #FFF"
      background="linear-gradient(90deg, #F64F2B 0%, #F2AB40 100%)"
    />
  )
}
