import EntityType from "../models/EntityType"

export default class PrescriptionDetailEntity implements EntityType {
  readonly medicineName: string
  readonly medicineNameForCustomer: string | null
  readonly spec: string
  readonly dosagePerDay: string
  readonly day: number | null
  readonly month: number | null

  constructor(json: any) {
    this.medicineName = json["medicine_name"]
    this.medicineNameForCustomer = json["medicine_name_for_customer"]
    this.spec = json["spec"]
    this.dosagePerDay = json["dosage_per_day"]
    this.day = json["day"]
    this.month = json["month"]
  }
}
