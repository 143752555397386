import { Box, Image, Text, Divider } from "@chakra-ui/react"
import React from "react"
import ArrowButton from "../../../ui-components/ArrowButton"
import { TenetColors } from "../../../ui-helpers/ChakraTheme"
import images from "../../../ui-helpers/ImageSources"

type Props = {
  url: string
  text: string
  caution: string | null
  cautionBoldPart: string | null
}

const CustomerHomeTalkButtonSection: React.FC<Props> = (props: Props) => {
  function splitWithBold(): string[] {
    const boldPart = props.cautionBoldPart
    const caution = props.caution

    if (!boldPart) {
      return caution ? [caution] : []
    } else if (caution) {
      const split = caution.split(boldPart)
      return [split[0], boldPart, split[1]] // 現状、太字箇所は1箇所のみと想定
    } else {
      return []
    }
  }
  const cautionTexts = splitWithBold()

  function cautionComponent() {
    return (
      <Box display="flex" m="24px 12px 0 12px" alignItems="center">
        <Image src={images.caution} height="48px" alt="ビックリマーク" />
        <Box width="32px" />
        <Box>
          <Text
            whiteSpace="pre-wrap"
            textAlign="left"
            lineHeight="27px"
            fontSize="15px"
            d="inline"
          >
            {cautionTexts[0]}
          </Text>
          <Text
            textColor={TenetColors.orange._200}
            whiteSpace="pre-wrap"
            textAlign="left"
            lineHeight="27px"
            fontSize="18px"
            d="inline"
          >
            {cautionTexts[1]}
          </Text>
          <Text
            whiteSpace="pre-wrap"
            textAlign="left"
            lineHeight="27px"
            fontSize="15px"
            d="inline"
          >
            {cautionTexts[2]}
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box height="24px" />
      <Divider color="rgba(225, 88, 58, 0.25)" />
      <Box bg={TenetColors.orange.transparent} p="18px 12px">
        <ArrowButton
          icon="userCircle"
          iconColor="orange"
          width="100%"
          height="120px"
          text={props.text}
          link={props.url}
          iconHeight="88px"
          linkTarget={true}
        />
        {props.caution ? cautionComponent() : null}
      </Box>
      <Divider color="rgba(225, 88, 58, 0.25)" />
    </>
  )
}

export default CustomerHomeTalkButtonSection
