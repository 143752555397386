import { Image, Box } from "@chakra-ui/react"
import React from "react"
import images from "../../../ui-helpers/ImageSources"

type Props = {
  picture: string
}

const CustomerHomeMedicinesPictureSection: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Image src={images.medicinePic} width="100%" alt="錠剤" />
      <Box height="24px" />
    </>
  )
}

export default CustomerHomeMedicinesPictureSection
