import { Box } from "@chakra-ui/react"
import React, { useCallback } from "react"
import PrescriptionModel from "../models/PrescriptionModel"
import ArrowButton from "./ArrowButton"
import PrescriptionDetailComponent from "./PrescriptionDetailComponent"
import * as H from "history"
import paths from "../helpers/ROUTES_PATH"

type Props = {
  prescriptions: PrescriptionModel[]
  shouldShowMoreButton: boolean
  history: H.History
}

const PrescriptionsListSection: React.FC<Props> = (props: Props) => {
  const handleOnClickMoreButton = useCallback(() => {
    props.history.push(paths.prescriptions)
  }, [props.history])

  return (
    <>
      <Box height="40px" />
      {props.prescriptions.map((p) => (
        <PrescriptionDetailComponent
          prescription={p}
          key={p.id}
          history={props.history}
        />
      ))}
      {props.shouldShowMoreButton ? (
        <Box display="flex" justifyContent="center" p="0 27%">
          <ArrowButton
            icon={null}
            iconColor="orange"
            iconHeight={null}
            width="100%"
            height="64px"
            text="もっと見る"
            onClick={handleOnClickMoreButton}
            linkTarget={false}
          />
        </Box>
      ) : null}
    </>
  )
}

export default PrescriptionsListSection
