import { Flex, Box } from "@chakra-ui/layout"
import React, { useCallback } from "react"
import IconButton from "../IconButton"
import TitleText from "../TitleText"
import * as H from "history"
import paths from "../../helpers/ROUTES_PATH"

interface Props {
  customerName: string | undefined
  history: H.History
}

const CustomerHomeTopBar: React.FC<Props> = (props: Props) => {
  const handleOnClickCustomerInfo = useCallback(() => {
    props.history.push(paths.customerDetail)
  }, [props.history])

  return (
    <Flex height="112px" align="center" justify="space-between">
      <TitleText text={props.customerName} maxWidth={"250px"} />
      <Box m="8px" />
      <IconButton
        isPressed={false}
        width={"80px"}
        height={"80px"}
        icon={"customer"}
        title={"会員情報"}
        fontSize={"14px"}
        iconHeight={"28px"}
        onClick={handleOnClickCustomerInfo}
      />
    </Flex>
  )
}

export default CustomerHomeTopBar
