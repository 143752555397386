import {
  Center,
  LinkBox,
  LinkOverlay,
  Text,
  Image,
  Box,
  Divider,
  Spacer,
} from "@chakra-ui/react"
import React from "react"
import { TenetColors } from "../../ui-helpers/ChakraTheme"
import images from "../../ui-helpers/ImageSources"

type Props = {}

const CustomerHomeFooterSection: React.FC<Props> = (props: Props) => {
  function createLinkComponent(text: string, path: string) {
    return (
      <>
        <LinkBox width="100%" p="22px 36px" display="flex" alignItems="center">
          <Text>{text}</Text>
          <Spacer />
          <Image src={images.nextGrayArrow} h="18px" alt="次へ矢印" />
          <LinkOverlay href={path} target="_blank" />
        </LinkBox>
        <Divider />
      </>
    )
  }

  function bottomLogoImageComponent() {
    return (
      <Center h="159px">
        <Image src={images.footerLogo} w="278px" alt="AGAスマクリのフッター" />
      </Center>
    )
  }

  return (
    <Box bg={TenetColors.white._100} m="0 -16px">
      {createLinkComponent(
        "よくある質問",
        "https://www.notion.so/ba6ec8b172d54c2384c81e6a6812b01c"
      )}
      {createLinkComponent(
        "利用規約",
        "https://agasmartclinic.notion.site/e628364777e44ed39b65773b83c54998"
      )}
      {createLinkComponent(
        "プライバシーポリシー",
        "https://agasmartclinic.notion.site/e7dd2be48031470fbc409a0412c9b589"
      )}
      {createLinkComponent(
        "特定商取引法に基づく表記",
        "https://agasmartclinic.notion.site/4d083ab842444cc6bdd205844d2fe9d6"
      )}
      {createLinkComponent(
        "お問い合わせ",
        "https://aga-sumakuri.studio.site/hp/contact"
      )}
      {bottomLogoImageComponent()}
    </Box>
  )
}

export default CustomerHomeFooterSection
