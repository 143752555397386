import { Box } from "@chakra-ui/react"
import React, { useCallback } from "react"
import paths from "./../../helpers/ROUTES_PATH"
import * as H from "history"
import ArrowButton from "../../ui-components/ArrowButton"
import PrescriptionShippingModel from "../../models/PrescriptionShippingModel"
import PrescriptionModel from "../../models/PrescriptionModel"

type Props = {
  prescriptionShipping: PrescriptionShippingModel
  prescription: PrescriptionModel
  index: number
  history: H.History
  prescriptionTerm: number | null
  kuronekoTrackigURL: string | null
}

const PrescriptionShippingItemComponent: React.FC<Props> = (props: Props) => {
  const date = new Date(props.prescriptionShipping.deliveryStartAt)
  const deliveryStartAt = `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()}`

  const handleOnClickShippingDetail = useCallback(() => {
    const path = `${paths.PrescriptionShippingDetail}${props.prescriptionShipping.id}`
    props.history.push({
      pathname: path,
      state: {
        prescriptionShipping: props.prescriptionShipping,
        prescription: props.prescription,
        kuronekoTrackingURL: props.kuronekoTrackigURL,
        index: props.index,
      },
    })
  }, [props.history, props.prescriptionShipping])

  return (
    <Box
      mt="16px"
      mb="16px"
      width="96%"
      ml="2%"
      onClick={handleOnClickShippingDetail}
    >
      <ArrowButton
        iconColor="orange"
        width="100%"
        height="72px"
        iconHeight="24px"
        icon="magnifier"
        text={`配送：${props.index}回目(${props.prescriptionTerm}ヶ月分)`}
        textBelow={
          props.prescriptionShipping.deliveryStartAt
            ? `配送予定:${deliveryStartAt}`
            : undefined
        }
        textAlign="center"
      />
    </Box>
  )
}

export default PrescriptionShippingItemComponent
