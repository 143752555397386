import { getAllMedicalExaminations } from "../../api-helpers/APICalls"
import MedicalExaminationsListModel from "../../models/MedicalExaminationsListModel"

export async function requestAllMedicalExaminations(): Promise<MedicalExaminationsListModel> {
  try {
    const list = await getAllMedicalExaminations()
    return list
  } catch (e) {
    throw e
  }
}
