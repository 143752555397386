import { Text } from "@chakra-ui/react"
import React from "react"

type Props = {
  title: string
}

const PageTitleSection: React.FC<Props> = (props: Props) => {
  return (
    <Text pt="24px" pb="40px" fontSize="20px" textAlign="center">
      {props.title}
    </Text>
  )
}

export default PageTitleSection
