import paths from "./API_PATH"
import fetchWithErrorHandling from "./fetchWithErrorHandling"
import MeEntity from "../entities/MeEntity"
import MeModel from "../models/MeModel"
import TopInfoModel from "../models/TopInfoModel"
import TopInfoEntity from "../entities/TopInfoEntity"
import MedicalExaminationsListModel from "../models/MedicalExaminationsListModel"
import MedicalExaminationsListEntity from "../entities/MedicalExaminationsListEntity"
import PrescriptionsListModel from "../models/PrescriptionsListModel"
import PrescriptionsListEntity from "../entities/PrescriptionsListEntity"
import AddressListModel from "../models/AddressListModel"
import AddressListEntity from "../entities/AddressListEntity"
import VoidEntity from "../entities/VoidEntity"
import VoidModel from "../models/VoidModel"

const enum MethodType {
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
}

export async function getTopInfo(): Promise<TopInfoModel> {
  return request({
    path: paths.topInfo,
    entityType: TopInfoEntity,
    modelType: TopInfoModel,
    method: MethodType.GET,
  })
}

export async function getMedicalExaminations(
  limit: number
): Promise<MedicalExaminationsListModel> {
  return request({
    path: `${paths.medicalExaminations}/?page_per_view=${limit}`,
    entityType: MedicalExaminationsListEntity,
    modelType: MedicalExaminationsListModel,
    method: MethodType.GET,
  })
}

// 初期リリースではページネーションを実装せず、100件取得
export async function getAllMedicalExaminations(): Promise<MedicalExaminationsListModel> {
  return request({
    path: paths.medicalExaminations,
    entityType: MedicalExaminationsListEntity,
    modelType: MedicalExaminationsListModel,
    method: MethodType.GET,
  })
}

export async function getPrescriptions(
  limit: number
): Promise<PrescriptionsListModel> {
  return request({
    path: `${paths.prescriptions}/?page_per_view=${limit}`,
    entityType: PrescriptionsListEntity,
    modelType: PrescriptionsListModel,
    method: MethodType.GET,
  })
}

// 初期リリースではページネーションを実装せず、100件取得
export async function getAllPrescriptions(): Promise<PrescriptionsListModel> {
  return request({
    path: paths.prescriptions,
    entityType: PrescriptionsListEntity,
    modelType: PrescriptionsListModel,
    method: MethodType.GET,
  })
}

export async function getMe(): Promise<MeModel> {
  return request({
    path: paths.me,
    entityType: MeEntity,
    modelType: MeModel,
    method: MethodType.GET,
  })
}

export async function patchMe(options: Object): Promise<VoidModel> {
  return request({
    path: paths.me,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.PATCH,
    body: options,
  })
}

export async function getAddresses(): Promise<AddressListModel> {
  return request({
    path: "/api/addresses",
    entityType: AddressListEntity,
    modelType: AddressListModel,
    method: MethodType.GET,
  })
}

export async function updateAddress(
  id: number,
  params: Object
): Promise<VoidModel> {
  return request({
    path: `/api/addresses/${id}`,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.PATCH,
    body: params,
  })
}

export async function createAddress(params: Object): Promise<VoidModel> {
  return request({
    path: `/api/addresses/`,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.POST,
    body: params,
  })
}

export async function usingAddress(id: number): Promise<VoidModel> {
  return request({
    path: `/api/addresses/${id}/use`,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.POST,
  })
}

export async function updateShipping(
  id: string,
  params: Object
): Promise<VoidModel> {
  return request({
    path: `/api/prescription_shippings/${id}`,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.PATCH,
    body: params,
  })
}

export async function updateShippingAllAfter(
  id: string,
  params: Object
): Promise<VoidModel> {
  return request({
    path: `/api/prescription_shippings/${id}/update_all_after`,
    entityType: VoidEntity,
    modelType: VoidModel,
    method: MethodType.PATCH,
    body: params,
  })
}

async function request<T, U>(params: {
  path: string
  entityType: { new (json: any): T }
  modelType: { new (entity: T): U }
  method: string
  body?: Object
}) {
  const { path, entityType, modelType, method, body } = params
  try {
    const json = await fetchWithErrorHandling(path, method, body || null)
    const entity = new entityType(json)
    return new modelType(entity)
  } catch (e) {
    throw e
  }
}
